import style_sheet from './Holiday.module.css';
import I18n from '../../../../localization';
import { useSelector } from "react-redux";
import { holiday_selectors } from "../../../../business/holiday";
import { useEffect } from "react";
import { Grid, GridColumn, Modal } from "semantic-ui-react";
import HolidayForm from './HolidayForm';

const { successfullyCreated } = holiday_selectors;

const HolidayModal = ({visible, close}) => {
    const created = useSelector((state) => successfullyCreated(state));

   
    useEffect(() => {
      close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [created])

    return (
        <Modal open={visible} onClose={close} closeIcon size="mini">
            <Grid centered padded>
                <GridColumn textAlign='center'>
                    <h3 className={style_sheet.modalHeader}>{I18n.t('ui.modal.holiday.title')}</h3>
                    <HolidayForm close={close} />
                </GridColumn>
            </Grid>
            
        </Modal>
    );
}

export default HolidayModal