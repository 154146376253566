// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReportProblemModal_reportProblemModalHeader__3X_uB {\n  margin-top: 16px !important; /* TODO: remove with themeing; */\n  padding: 0 10px;\n}\n\n.ReportProblemModal_contentWrapper__2g5mX {\n  display: flex;\n  flex-direction: column;\n  align-self: stretch;\n  align-items: stretch;\n  margin-top: 32px;\n}\n\n.ReportProblemModal_reportProblemForm__3oBUi {\n  margin-top: 16px;\n  display: flex;\n  align-self: stretch;\n  flex-direction: column;\n}\n\n.ReportProblemModal_fieldLabel__QgZzh {\n  text-align: left;\n  font-size: 14px;\n  margin-bottom: 16px;\n}\n\n.ReportProblemModal_formNote__1AbIk {\n  font-size: 14px;\n  color: #6F6F6F;\n}", "",{"version":3,"sources":["webpack://src/views/shared/ReportProblemModal/ReportProblemModal.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B,EAAE,gCAAgC;EAC7D,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB","sourcesContent":[".reportProblemModalHeader {\n  margin-top: 16px !important; /* TODO: remove with themeing; */\n  padding: 0 10px;\n}\n\n.contentWrapper {\n  display: flex;\n  flex-direction: column;\n  align-self: stretch;\n  align-items: stretch;\n  margin-top: 32px;\n}\n\n.reportProblemForm {\n  margin-top: 16px;\n  display: flex;\n  align-self: stretch;\n  flex-direction: column;\n}\n\n.fieldLabel {\n  text-align: left;\n  font-size: 14px;\n  margin-bottom: 16px;\n}\n\n.formNote {\n  font-size: 14px;\n  color: #6F6F6F;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reportProblemModalHeader": "ReportProblemModal_reportProblemModalHeader__3X_uB",
	"contentWrapper": "ReportProblemModal_contentWrapper__2g5mX",
	"reportProblemForm": "ReportProblemModal_reportProblemForm__3oBUi",
	"fieldLabel": "ReportProblemModal_fieldLabel__QgZzh",
	"formNote": "ReportProblemModal_formNote__1AbIk"
};
export default ___CSS_LOADER_EXPORT___;
