import _ from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon, Input, Popup } from "semantic-ui-react"
import { createCustomTag } from "../../../../business/custom_tags/actions";
import { createCustomTagErrors, isCreating } from "../../../../business/custom_tags/selectors";
import I18n from '../../../../localization';
import style_sheet from './CreateCustomTag.module.css';
import CustomTagList from "./CustomTagList";

const CreateCustomTag = ({orderId, customTags, origin}) => {
    const [visible, setVisible] = useState(false);
    const [customTag, setCustomTag] = useState({color: '#000000'});
    const dispatch = useDispatch();
    const creating = useSelector((state) => isCreating(state));
    const errors = useSelector((state) => createCustomTagErrors(state));


    const showModal = () => {
        setVisible(!visible);
    }

    const closeModal = (e) => {
        if(e instanceof PointerEvent){
            setVisible(false)
        }
    }

    const addCustomTag = () => {
        dispatch(createCustomTag(customTag));
    }

    return (
        <Popup open={visible} onClose={(e) => closeModal(e)} flowing hoverable trigger={<Button
            circular
            basic
            size="mini"
            icon
            title={I18n.t('accessibility.title.add_custom_tags')}
            onClick={() => showModal()}>
            <Icon name='plus' />
        </Button>} dimmer='inverted'>
            <div>
                <CustomTagList orderId={orderId} customTags={customTags} origin={origin}/>
                <input id="color-picker" className={style_sheet.colorPicker} type="color" onChange={(e) => setCustomTag({...customTag, color: e.target.value})} />
                <Input id="name" icon='tags'  action={
                    {color: 'blue', labelPosition: 'left', icon: 'plus', loading: creating, content: 'Create Tag', onClick: () => addCustomTag() }} 
                    iconPosition="left" placeholder='Tag Name' labelPosition='right' type="text" 
                    onChange={(e) => setCustomTag({...customTag, name: e.target.value})} />
                {errors && errors.length > 0 && <div className={style_sheet.errorWrapper}>
                    {_.map(errors, (e, i) => <p key={`form_error${i}`}>{e}</p>)}
                </div>}
            </div>
        </Popup>
    )
}

export default CreateCustomTag