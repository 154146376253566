// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MBForm_submitButton__25mnq {\n  margin: 16px 0 0 0;\n  height: 36px;\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background-color: #222;\n  border-radius: 6px;\n  cursor: pointer;\n  color: #FFF;\n}\n\n.MBForm_submitButton__25mnq:hover {\n  background-color: #6F6F6F;\n}\n\n.MBForm_submitButtonText__x5XaL {\n  font-size: 14px;\n  font-weight: bold;\n}\n\n.MBForm_inverted__31k79 {\n  background-color: #FFF;\n  color: #222;\n  border: 1px solid #222;\n}\n\n.MBForm_disabled__2bH-I {\n  background-color: #BFBFBF;\n  color: #FFF;\n}\n\n.MBForm_disabled__2bH-I:hover {\n  background-color: #BFBFBF;\n  cursor: not-allowed;\n}\n\n", "",{"version":3,"sources":["webpack://src/views/elements/MBForm/MBForm.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":[".submitButton {\n  margin: 16px 0 0 0;\n  height: 36px;\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background-color: #222;\n  border-radius: 6px;\n  cursor: pointer;\n  color: #FFF;\n}\n\n.submitButton:hover {\n  background-color: #6F6F6F;\n}\n\n.submitButtonText {\n  font-size: 14px;\n  font-weight: bold;\n}\n\n.inverted {\n  background-color: #FFF;\n  color: #222;\n  border: 1px solid #222;\n}\n\n.disabled {\n  background-color: #BFBFBF;\n  color: #FFF;\n}\n\n.disabled:hover {\n  background-color: #BFBFBF;\n  cursor: not-allowed;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submitButton": "MBForm_submitButton__25mnq",
	"submitButtonText": "MBForm_submitButtonText__x5XaL",
	"inverted": "MBForm_inverted__31k79",
	"disabled": "MBForm_disabled__2bH-I"
};
export default ___CSS_LOADER_EXPORT___;
