import style_sheet from './ShippingMultiOptions.module.css';
import I18n from '../../../localization';
import { Button, ButtonGroup, Icon } from 'semantic-ui-react';
import PrintLabelModal from '../PrintLabelModal/PrintLabelModal';
import PickListModal from '../PickListModal/PickListModal';
import { useDispatch } from 'react-redux';
import { getPackingSlips } from '../../../business/order/actions';
import _ from 'lodash';
import { useState } from 'react';
import ConfirmOrderPopup from '../ConfirmOrderPopup/ConfirmOrderPopup';
import { getBulkLabels } from '../../../business/label/actions';
import ConfirmPresaleOrderPopup from '../ConfirmOrderPopup/ConfirmPresaleOrderPopup';


const ShippingMultiOptions = ({selectedOrders, confirmOrder, createLabel, pickList, print, confirmPresaleOrder, confirmAllOrders}) => {
    const dispatch = useDispatch();
    const [showButtons, setShowButtons] = useState(false);
    const handlePrint = () => {
        const params  = _.map(selectedOrders, 'id');
        if(params){
            const prepare_params = 'uuids[]='+params.join('&uuids[]=');
            dispatch(getPackingSlips(prepare_params));
        }
    }

    const handlePrintLabels = () => {
        const params  = _.map(selectedOrders, 'id');
        if(params){
            const prepare_params = 'shipment_ids[]='+params.join('&shipment_ids[]=');
            dispatch(getBulkLabels(prepare_params));
        }
    }

    return (
        <div className={style_sheet.buttonContainer}>
            {confirmOrder && <ConfirmOrderPopup orders={selectedOrders} />}
            {confirmPresaleOrder && <ConfirmPresaleOrderPopup orders={selectedOrders} confirmAllOrders={confirmAllOrders} />}
            {createLabel && <PrintLabelModal orders={selectedOrders}/>}
            {pickList && <PickListModal orders={selectedOrders} />}

            {print && <ButtonGroup basic vertical>
                 <Button
                    secondary
                    inverted
                    tabIndex={1}
                    icon
                    title={I18n.t('accessibility.title.print')}
                    onClick={() => setShowButtons(!showButtons)}>
                    <Icon name='print' />
                    <span className={style_sheet.buttonPrint}>{I18n.t('ui.button.print')}</span>
                    <Icon name='angle down' />
                </Button>
                {showButtons && !_.isEmpty(selectedOrders) && 
                <>
                <Button
                    tabIndex={2}
                    secondary
                    inverted
                    icon
                    title={I18n.t('accessibility.title.packing_slips')}
                    onClick={() => handlePrint()}>
                    <Icon name='print' />
                    <span className={style_sheet.buttonPrint}>{I18n.t('ui.button.packing_slips')}</span>
                </Button>
                <Button
                    tabIndex={2}
                    secondary
                    inverted
                    icon
                    title={I18n.t('accessibility.title.print_labels')}
                    onClick={() => handlePrintLabels()}>
                    <Icon name='print' />
                    <span className={style_sheet.buttonPrint}>{I18n.t('ui.button.print_labels')}</span>
                </Button>
                </>
                
                }

            </ButtonGroup>}
            
        </div>
    )
}


export default ShippingMultiOptions;