//@flow

import { globalizeSelectors } from '../utils/globalizeSelectors';
import type { LocalState } from './reducer';

const LOCAL_PATH = 'packagePreset';

// local selectors
export const listPackages = (state: LocalState) => state?.listPackages;
export const createPackagePresetsErrors = (state: LocalState) => state?.createPackagePresetsErrors;
export const isFetching = (state: LocalState) => state?.is_fetching;
export const updatingId = (state: LocalState) => state?.updating_id;
export const isCreating = (state: LocalState) => state?.is_creating;
export const successfullyCreated = (state: LocalState) => state?.successfully_created;

const selectors = {
  ...globalizeSelectors(LOCAL_PATH, {
    listPackages,
    createPackagePresetsErrors,
    isFetching,
    updatingId,
    isCreating,
    successfullyCreated
  })
};

// global selectors
export default selectors;