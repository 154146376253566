import { useState } from "react";
import MBButton from "../../../elements/MBButton"
import MBIcon from "../../../elements/MBIcon";
import I18n from '../../../../localization';
import HolidayModal from "./HolidayModal";

import { fetchHolidays, resetNewHolidayForm } from "../../../../business/holiday/actions";
import { useDispatch } from "react-redux";

const NewHolidayButton = () => {
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const closeModal = () =>{
        dispatch(fetchHolidays());
        dispatch(resetNewHolidayForm());
        setVisible(false);
    }

    return (
        <div>
            <MBButton
                title={I18n.t('accessibility.title.add_holiday')}
                button_type="link"
                size="large"
                onClick={() => setVisible(!visible)}>
                <MBIcon icon="add" color="mb_red" size="small" />
                <div>{I18n.t('ui.button.new_holiday')}</div>
            </MBButton>
            <HolidayModal visible={visible} close={closeModal}/>
        </div>
    )
}

export default NewHolidayButton;