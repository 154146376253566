import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormField, Grid, GridColumn, Input, Message, Modal } from "semantic-ui-react";
import I18n from '../../../localization';
import { updateOrderShipping } from "../../../business/order/actions";
import OrderTypeIcon from "../OrderTypeIcon";
import { nextState, recipientName } from "../../../business/order/helpers";
import { updatingResult, updatingShippingResult } from "../../../business/order/selectors";
import _ from 'lodash';


const ShippingOrderStateModal = ({order}) => {
    const [visible, setVisible] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false)
    const [trackingNumber, setTrackingNumber] = useState();
    const errors = useSelector((state) => updatingResult(state));
    const updateResult = useSelector((state) => updatingShippingResult(state));
    const dispatch = useDispatch();

    const updateOrderHandle = () => {
        setUpdateSuccess(false)
        dispatch(updateOrderShipping(order.id, {tracking_number: trackingNumber}));
    }

    useEffect(() => {
        if(updateResult){
            setUpdateSuccess(true);
        }
    }, [updateResult])

    const handleTrackingNumber = (value) => {
        // const clearString = value.replaceAll(/[^A-Za-z0-9_]/g,"").trim()
        setTrackingNumber(value)
    }

    const scope = `ui.modal.shipped.${nextState(order)}`;
    const order_title = I18n.t('ui.modal.order');
    const successMessage = I18n.t('accessibility.title.waiting_carrier');

    return (
        <Modal onClose={() => setVisible(!visible)} closeIcon size="mini" trigger={
            <Button
                color='black'
                title={I18n.t('accessibility.title.ready_to_ship')}
                onClick={() => setVisible(!visible)}>
                {I18n.t('ui.button.ready_to_ship')}
            </Button>
        }>
            <Grid centered padded>
                <GridColumn textAlign='center'>
                <OrderTypeIcon type='shipped'/>
                    <h3>{I18n.t(`${scope}.question`, {
                    recipient_name: order.recipient ? order.recipient : recipientName(order),
                    order_title
                    })}</h3>
                    <Form error={!_.isEmpty(errors)} success={updateSuccess}>
                        <FormField id="input-tracking-number" value={trackingNumber} control={Input} onChange={(e) => handleTrackingNumber(e.target.value)} required placeholder={I18n.t('ui.modal.shipped.delivered.label.tracking_number')}/>
                        <Button color="black" fluid onClick={() => updateOrderHandle()}>{I18n.t('ui.modal.shipped.delivered.button')}</Button>
                        <Message error hidden={_.isEmpty(errors)} content={errors} />
                        <Message success hidden={!updateSuccess} content={successMessage} />
                    </Form>
                </GridColumn>
            </Grid>

        </Modal>
    )
}

export default ShippingOrderStateModal;
