// @flow

import {filter, switchMap} from 'rxjs/operators';
import {api} from '../networking';
import createActionsForRequest from '../utils/create_actions_for_request';

export const createCustomTag = (action$: Object) => {
  const custom_tag_response_action$ = action$
  .pipe(
    filter(action => action.type === 'CUSTOM_TAG:CREATE'),
    switchMap(action => {
      return createActionsForRequest(api.createCustomTag({...action.payload}), action.type, action.meta);
      }));

  return custom_tag_response_action$;
};

export const destroyCustomTag = (action$: Object) => {
  const custom_tag_response_action$ = action$
    .pipe(
      filter(action => action.type === 'CUSTOM_TAG:DESTROY'),
      switchMap(action => {
        return createActionsForRequest(api.destroyCustomTag(action.payload), action.type, action.meta);
      }));

  return custom_tag_response_action$;
};

export const fetchCustomTags = (action$: Object) => {
  const custom_tag_response_action$ = action$
    .pipe(
      filter(action => action.type === 'CUSTOM_TAG:FETCH'),
      switchMap(action => {
        return createActionsForRequest(api.fetchCustomTags(), action.type, action.meta);
      }));

  return custom_tag_response_action$;
};

export const updateCustomTag = (action$: Object) => {
  const custom_tag_response_action$ = action$
    .pipe(
      filter(action => action.type === 'CUSTOM_TAG:UPDATE'),
      switchMap(action => {
        return createActionsForRequest(api.updateCustomTag(action.payload), action.type, action.meta);
      }));

  return custom_tag_response_action$;
};

export const assignCustomTag = (action$: Object) => {
  const custom_tag_response_action$ = action$
    .pipe(
      filter(action => action.type === 'CUSTOM_TAG:ASSIGN'),
      switchMap(action => {
        return createActionsForRequest(api.assignCustomTag(action.payload), action.type, action.meta);
      }));

  return custom_tag_response_action$;
};

export const unassignCustomTag = (action$: Object) => {
  const custom_tag_response_action$ = action$
    .pipe(
      filter(action => action.type === 'CUSTOM_TAG:UNASSIGN'),
      switchMap(action => {
        return createActionsForRequest(api.unassignCustomTag(action.payload), action.type, action.meta);
      }));

  return custom_tag_response_action$;
};
