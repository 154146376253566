// @flow

import React, {PureComponent} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import classNames from 'classnames/bind';
import I18n from '../../../localization';
import style_sheet from './OrderListWrapper.module.css';
import NotificationFeed from './NotificationFeed';
import OrderList from '../../shared/OrderList';
import ShippingListWrapper from '../ShippingListWrapper/ShippingListWrapper';
import {session_selectors} from '../../../business/session';
import { isShippingOnly, isShippingSupplier,  } from '../../../business/session/helpers';
import PreSaleWrapper from '../PreSaleWrapper/PreSaleWrapper';
import { fetchBackOrder, fetchPreSale, fetchShipped } from '../../../business/order/actions';
import { listTotalCount } from '../../../business/order/helpers';
import { updateUnconfirmed } from '../../../business/session/actions';

const cx = classNames.bind(style_sheet);

const ORDERS_SCENE_TABS = ['completed', 'future', 'all', 'exception'];

type OrderListWrapperProps = { pushRoute: (string) => void, fetchPreSale: () => void, fetchBackOrder: () => void, fetchUnconfirmed: () => void };

export class OrderListWrapper extends PureComponent {
  props: OrderListWrapperProps

  UNSAFE_componentWillMount() {
    this.props.fetchPreSale({per_page: 5, page: 1, state: 'pre_sale'});
    this.props.fetchBackOrder({per_page: 5, page: 1, state: 'back_order'});
    this.props.fetchShipped({per_page: 5, page: 1});
    this.props.fetchUnconfirmed();
  }

  render() {
    const { location, supplier, total_count, total_count_back, total_count_shipping } = this.props;
    const shippingOnly = isShippingOnly(supplier);
    const defaultTab = shippingOnly ? '/orders/shipping' : '/orders/active';

    // default to active tab
    if (location.pathname === '/orders' || (location.pathname === '/orders/active' && shippingOnly)){
      return  <Redirect to={{pathname: defaultTab, state: {from: location}}}/>;
    } 
    return (
      <div className={style_sheet.sceneWrapper}>
        <NotificationFeed/>
        <div className={style_sheet.orderListsWrapper}>
          <div className={style_sheet.tabWrapper}>
            {!shippingOnly && 
              <div
                role="link"
                tabIndex={ORDERS_SCENE_TABS.length+1}
                title={I18n.t(`accessibility.title.active_orders`)}
                className={cx({tab: true, activeTab: location.pathname.endsWith('active')})}
                onClick={() => this.props.pushRoute(`/orders/active`)}
                key='active'>
                {I18n.t('ui.tab.active')}
              </div>}
            {(isShippingSupplier(supplier) || total_count_shipping > 0) && 
            <><div
                role="link"
                tabIndex={ORDERS_SCENE_TABS.length+1}
                title={I18n.t(`accessibility.title.shipping_orders`)}
                className={cx({tab: true, activeTab: location.pathname.endsWith('shipping')})}
                onClick={() => this.props.pushRoute(`/orders/shipping`)}
                key='shipping'>
                {I18n.t('ui.tab.shipping')}
              </div>
            </>}
            {(supplier.presale_eligible || total_count > 0) && 
              <div
                role="link"
                tabIndex={ORDERS_SCENE_TABS.length+2}
                title={I18n.t(`accessibility.title.presale_orders`)}
                className={cx({tab: true, activeTab: location.pathname.endsWith('presale')})}
                onClick={() => this.props.pushRoute(`/orders/presale`)}
                key='presale'>
                {I18n.t('ui.tab.presale')}
              </div>}
              {(supplier.accepts_back_orders || total_count_back > 0) && 
              <div
                role="link"
                tabIndex={ORDERS_SCENE_TABS.length+3}
                title={I18n.t(`accessibility.title.backorder_orders`)}
                className={cx({tab: true, activeTab: location.pathname.endsWith('backorder')})}
                onClick={() => this.props.pushRoute(`/orders/backorder`)}
                key='backorder'>
              {I18n.t('ui.tab.backorder')}
            </div>}
            {ORDERS_SCENE_TABS.map((tab, index) => (
              <div
                role="link"
                tabIndex={index+1}
                title={I18n.t(`accessibility.title.${tab}_orders`)}
                className={cx({tab: true, activeTab: location.pathname.endsWith(tab)})}
                onClick={() => this.props.pushRoute(`/orders/${tab}`)}
                key={tab}>
                {I18n.t(`ui.tab.${tab}`)}
              </div>
            ))}
            
            <div className={style_sheet.tabBuffer}/>
          </div>
          <div className={style_sheet.tabContentWrapper}>
            <Switch>
              {ORDERS_SCENE_TABS.map((tab) => (
                <Route key={`${tab}_route`} exact path={`/orders/${tab}`} component={OrderList}/>
              ))}
              <Route key={'active_route'} exact path={'/orders/active'} component={OrderList}/>
              <Route key={'shipping_route'} exact path={'/orders/shipping'} component={ShippingListWrapper}/>
              <Route key={'presale_route'} exact path={'/orders/presale'} render={(props) => <PreSaleWrapper {...props} isPreSale={true} />} />
              <Route key={'backorder_route'} exact path={'/orders/backorder'} render={(props) => <PreSaleWrapper {...props} isPreSale={false} />}/>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

const OrderListWrapperSTP = state => {
  return (state) => {
    return {
      supplier: session_selectors.currentSupplier(state),
      total_count: listTotalCount(state.order.presale),
      total_count_back: listTotalCount(state.order.back),
      total_count_shipping: listTotalCount(state.order.shipped),
    };
  };
};

const OrderListWrapperDTP = {
  fetchPreSale: fetchPreSale,
  fetchBackOrder: fetchBackOrder,
  fetchShipped: fetchShipped,
  fetchUnconfirmed: updateUnconfirmed,
  pushRoute: push,
};

const OrderListWrapperContainer = connect(OrderListWrapperSTP, OrderListWrapperDTP)(OrderListWrapper);

export default OrderListWrapperContainer;
