//@flow

import React, {PureComponent} from 'react';
import {Dropdown} from 'semantic-ui-react';
import I18n from '../../../localization';
import style_sheet from './form_fields.module.css';

const CARRIER_TYPE_OPTIONS = [
  {value: 'ups', text: I18n.t('form.option.ups')},
  {value: 'fedex', text: I18n.t('form.option.fedex')},
  {value: 'gls_us', text: I18n.t('form.option.gls')},
  // {value: 'lso', text: I18n.t('form.option.lso')},
  {value: 'better_trucks', text: I18n.t('form.option.better_trucks')}
];

type CarrierTypeFieldProps = { onChangeText: (string, Object) => void, options: Array<Object>, updateCarrier: () => void };

class CarrierTypeField extends PureComponent {
  props: CarrierTypeFieldProps

  static defaultProps = {name: 'carrier'}
  input: ReactClass<*>

  onChange = (_e, {value}) => {
    this.props.updateCarrier(value)
  }

  render() {
    return (
      <Dropdown
        selection
        placeholder={I18n.t('form.placeholder.carrier_type')}
        options={CARRIER_TYPE_OPTIONS}
        className={style_sheet.textInput}
        onChange={this.onChange}/>
    );
  }
}

export default CarrierTypeField;
