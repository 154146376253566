import { useEffect } from 'react';
import MBTable from '../../../elements/MBTable';
import style_sheet from './Holiday.module.css';

import { holiday_helpers, holiday_selectors } from '../../../../business/holiday';
import MBLoader from '../../../elements/MBLoader';
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { destroyHoliday, fetchHolidays } from '../../../../business/holiday/actions';
import I18n from '../../../../localization';
import MBIcon from '../../../elements/MBIcon';
import MBButton from '../../../elements/MBButton';
import { isFetching } from '../../../../business/holiday/selectors';
import NewHolidayButton from './NewHolidayButton';

const {HOLIDAYS_COLUMN_ORDER} = holiday_helpers;
const { listHolidays } = holiday_selectors;

const Holidays = () => {
    const fetching = useSelector((state) => isFetching(state));
    const dispatch = useDispatch();
    const holidayList = useSelector((state) => listHolidays(state));

    useEffect(() =>{
      dispatch(fetchHolidays());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderTableHeader = () => (
      <MBTable.Header>
        {HOLIDAYS_COLUMN_ORDER.map(column_name => {
          if (column_name === 'mobile_summary') return null; // no header for mobile summary column
          return (
            <MBTable.HeaderCell mobile_hidden
                                key={`${column_name}header`}>{I18n.t(`ui.table.${column_name}`)}</MBTable.HeaderCell>
          );
        })}
      </MBTable.Header>
    );

    const renderTableBody = (holidays) => {
        if (fetching) return <MBLoader/>;
        return (<MBTable.Body>{holidays.map(holidays => renderRow(holidays))}</MBTable.Body>);
      };
    
    const renderRow = (holidays) => {
      return (<MBTable.Row key={`${holidays.id}row`}>
        {HOLIDAYS_COLUMN_ORDER.map(col => {
          return (
            <MBTable.Cell
              key={`${holidays.date}${col}`}
              mobile_only={col === 'mobile_summary'}
              mobile_hidden={col !== 'mobile_summary'}>
              {formatCellDatum(col, holidays)}
            </MBTable.Cell>
          );
        })}
      </MBTable.Row>);
    };

    const deleteHolidays = (id) => {
        if (window.confirm(I18n.t('ui.alert.confirm_delete_holiday'))) {
            dispatch(destroyHoliday(id));
        }
    }

    const renderButton = (holidays) => {
      return <MBButton
                title={I18n.t('accessibility.title.delete_holiday')}
                onClick={() => deleteHolidays(holidays.id)}
                button_type="link"
                size="large">
                <MBIcon icon="delete" color="mb_red" size="small" />
                <div>{I18n.t('ui.button.delete_holiday')}</div>
            </MBButton>
    }
        
    

    const formatCellDatum = (key, holidays) => {
        switch (key) {
            case 'date':
                return <p>{holidays.date}</p>
            case 'edit':
                return (renderButton(holidays));
            default:
              return <p></p>;
          }
    }

    return (
        <div className={style_sheet.tableWrapper}>

            <NewHolidayButton />
            {!_.isEmpty(holidayList) &&
                <MBTable.Table>
                {renderTableHeader()}
                {renderTableBody(holidayList)}
                </MBTable.Table>
            }
            
        </div>

    );
}


export default Holidays; 