// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MinibarClosedModal_minibarClosedModalHeader__1ZPU8 {\n  margin-top: 16px !important; /* TODO: remove with themeing; */\n  padding: 0 10px;\n}\n", "",{"version":3,"sources":["webpack://src/views/shared/MinibarClosedModal/MinibarClosedModal.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B,EAAE,gCAAgC;EAC7D,eAAe;AACjB","sourcesContent":[".minibarClosedModalHeader {\n  margin-top: 16px !important; /* TODO: remove with themeing; */\n  padding: 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"minibarClosedModalHeader": "MinibarClosedModal_minibarClosedModalHeader__1ZPU8"
};
export default ___CSS_LOADER_EXPORT___;
