import style_sheet from './PackagePresets.module.css';
import I18n from '../../../../localization';
import { useSelector } from "react-redux";
import { package_presets_selectors } from "../../../../business/package_presets";
import { useEffect, useState } from "react";
import { Grid, GridColumn, Modal } from "semantic-ui-react";
import PackagePresetsForm from './PackagePresetsForm';

const { successfullyCreated } = package_presets_selectors;


const PackagePresetsModal = ({visible, close}) => {
    const [visibleModal, setVisibleModal] = useState(visible);
    const created = useSelector((state) => successfullyCreated(state));

    useEffect(() => {
        setVisibleModal(visible)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [visible])

    useEffect(() => {
      close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [created])

    return (
        <Modal open={visibleModal} onClose={() => setVisibleModal(!visibleModal)} closeIcon size="mini">
            <Grid centered padded>
                <GridColumn textAlign='center'>
                    <h3 className={style_sheet.modalHeader}>{I18n.t('ui.modal.package_presets_configuration.title')}</h3>
                    <PackagePresetsForm close={close} />
                </GridColumn>
            </Grid>
            
        </Modal>
    );
}

export default PackagePresetsModal