import { useState } from "react";
import MBButton from "../../../elements/MBButton"
import MBIcon from "../../../elements/MBIcon";
import I18n from '../../../../localization';
import PackagePresetsModal from "./PackagePresetsModal";

const NewPackagePresetButton = () => {
    const [visible, setVisible] = useState(false);
   
    const closeModal = () =>{
        setVisible(false);
    }

    return (
        <div>
            <MBButton
                title={I18n.t('accessibility.title.add_package_preset')}
                button_type="link"
                size="large"
                onClick={() => setVisible(!visible)}>
                <MBIcon icon="add" color="mb_red" size="small" />
                <div>{I18n.t('ui.button.new_package_preset')}</div>
            </MBButton>
            <PackagePresetsModal visible={visible} close={closeModal}/>

        </div>
    )
}

export default NewPackagePresetButton;