//@flow

import { globalizeSelectors } from '../utils/globalizeSelectors';
import type { LocalState } from './reducer';

const LOCAL_PATH = 'custom_tags';

// local selectors
export const listTags = (state: LocalState) => state.custom_tag.listTags;
export const createCustomTagErrors = (state: LocalState) => state.custom_tag.createCustomTagErrors;
export const isFetching = (state: LocalState) => state.custom_tag.is_fetching;
export const updatingId = (state: LocalState) => state.custom_tag.updating_id;
export const isCreating = (state: LocalState) => state.custom_tag.is_creating;
export const isDestroying = (state: LocalState) => state.custom_tag.is_destroying;
export const isAssigning = (state: LocalState) => state.custom_tag.is_assigning;
export const successfullyCreated = (state: LocalState) => state.custom_tag.successfully_created;
export const successfullyDestroyed = (state: LocalState) => state.custom_tag.successfully_destroyed;
export const successfullyAssigned = (state: LocalState) => state.custom_tag.successfully_assigned;
export const successfullyUnassigned = (state: LocalState) => state.custom_tag.successfully_unassigned;

const selectors = {
  ...globalizeSelectors(LOCAL_PATH, {
    listTags,
    createCustomTagErrors,
    isFetching,
    updatingId,
    isCreating,
    isDestroying,
    isAssigning,
    successfullyCreated,
    successfullyDestroyed,
    successfullyAssigned,
    successfullyUnassigned
  })
};

// global selectors
export default selectors;