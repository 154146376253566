import MBButton from "../../elements/MBButton";
import MBIcon from "../../elements/MBIcon";
import style_sheet from './ShippingFiltersModal.module.css';
import I18n from '../../../localization';
import { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";


const ShippingFiltersModal = ({are_applied_filters, toggleVisibility, visible, filters, handleApplyFilters}) => {
  
  const [localFilters, setLocalFilters] = useState({});
    
  const statusOptions = [
    {"text": "Unconfirmed", "key": "unconfirmed","value": "unconfirmed"}, 
    {"text": "Confirmed", "key": "confirmed", "value": "confirmed"}];

  //Hooks
  useEffect(() => {
    setLocalFilters({quantity: '', ...filters});
  }, [filters]);

    return (
        <div className={style_sheet.wrapper}>
        <MBButton button_type={are_applied_filters ? 'active' : 'secondary'} size="large" onClick={toggleVisibility}>
          <MBIcon icon="filter" color={are_applied_filters ? 'mb_white' : 'mb_black'}/>
          <p className={style_sheet.filterButtonText}>{I18n.t('ui.button.filter')}</p>
        </MBButton>
        {visible &&
        <div className={style_sheet.filterDropdown}>
          <div className={style_sheet.dateRow}>
            <div className={style_sheet.halfRow}>
              <p className={style_sheet.filterLabel}>{I18n.t('ui.header.status')}</p>
              <Dropdown
                placeholder="Select option"
                onChange={(e, data) => setLocalFilters({...localFilters, status: data.value})}
                value={localFilters.status}
                selection
                options={statusOptions}
                className={style_sheet.reportTypeSelect}/>
              </div>
            <div className={style_sheet.halfRow}>
              <p className={style_sheet.filterLabel}>{I18n.t('ui.header.botlle_count')}</p>
              <input
                type="number"
                onChange={(e) => setLocalFilters({...localFilters, quantity: e.target.value})}
                value={localFilters.quantity}
                className={style_sheet.filterInput}/>
            </div>
          </div>

          <div className={style_sheet.dateRow}>
            <MBButton expand onClick={() => handleApplyFilters(localFilters)}>
              {I18n.t('ui.button.apply_filters')}
            </MBButton>
          </div>
        </div>
        }
      </div>
    )
};

export default ShippingFiltersModal
