type Weight = {
    value: number,
    unit: string
};
type Dimensions = {
    height: number,
    width: number,
    length: number,
    unit: string
};

type Package = {
    weight: Weight,
    dimensions: Dimensions
}

type Shipment = {
    carrier: String,
    packages: Package,
    confirmation: String
}

type Label = {
    order_id: number,
    supplier_id: number,
    shipments: Arrray<Shipment>
}

export const createLabel = (label: Label): Action => ({
    type: 'LABEL:CREATE',
    meta: label
});

export const cleanLabel = (): Action => ({
    type: 'LABEL:CLEAN',
    meta: {}
});

export const calculateShippingPrice = (order_id, pack): Action => ({
    type: 'LABEL:SHIPPING_PRICE',
    meta: {order_id: order_id, package: pack}
});

export const destroyPackage = (order_id, package_id: number): Action => ({
    type: 'LABEL:DESTROY',
    payload: { package: package_id, order: order_id }
});

export const getBulkLabels = (options: Array): Action => ({
    type: 'LABEL:BULK',
    meta: options
  });
  