// @flow

import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import * as Ent from '@minibar/store-business/src/utils/ent';
import type Order from '../../../business/order';
import {order_helpers, order_actions} from '../../../business/order';
import MBLoader from '../../elements/MBLoader';
import style_sheet from './OrderDetail.module.css';
import OrderDetailHeader from './OrderDetailHeader';
import OrderDetailInfo from './OrderDetailInfo';
import OrderItemList from './OrderItemList';
import OrderAdjustmentList from './OrderAdjustmentList';
import OrderDetailHistory from './OrderDetailHistory';
import OrderDetailEngraving from './OrderDetailEngraving';
import MinibarClosedModal from '../../shared/MinibarClosedModal';
import {isMinibarClosed} from '../../../business/utils/is_minibar_closed';
import type WorkingHour from '../../../business/settings';
import {settings_selectors} from '../../../business/settings';
import { session_actions, session_selectors } from '../../../business/session';

const {workingHours} = settings_selectors;

const {orderItems, engravingOrderItems, amounts, orderAdjustmentIds} = order_helpers;

type OrderDetailProps = { order: Order, orders: Array<Order>, workingHours: Array<WorkingHour>, fetchOrder: (string) => void, fetchOrders: () => void};

export class OrderDetail extends PureComponent {
  props: OrderDetailProps

  state = {isMinibarClosedModalHidden: true}
  

  componentDidMount() {
    if(!this.props.orders){
      this.props.fetchOrders();
    }
    if (!this.props.order) {
      this.props.fetchOrder(this.props.match.params.number);
    } 
  }

  handleClose = () => this.setState({isMinibarClosedModalHidden: true})

  isMinibarClosed = () => this.setState({isMinibarClosedModalHidden: !isMinibarClosed(this.props.workingHours)})

  render() {
    const {order, orders, history} = this.props; 
    if (!order || !order.billing){
      this.props.fetchOrder(this.props.match.params.number);
      return <div className={style_sheet.loaderWrapper}><MBLoader size="large" type="spinner"/></div>;
    } 
    
    const index = _.findIndex(orders, o => o.id === order.id);
    const nextId = index > 0 ? orders[index-1]?.id : null;
    const prevId = index < orders?.length ? orders[index+1]?.id : null;
    return (
      <div className={style_sheet.sceneWrapper}>
        <OrderDetailHeader order={order} isMinibarClosed={this.isMinibarClosed} prevId={prevId} nextId={nextId} origin={history.location.state?.origin}/>
        { engravingOrderItems(order).length > 0 ? <OrderDetailEngraving order_items={engravingOrderItems(order)} /> : null }
        <OrderDetailInfo order={order}/>
        <OrderItemList order_items={orderItems(order)} amounts={amounts(order)}/>
        <OrderAdjustmentList order_id={order.id} adjustment_ids={orderAdjustmentIds(order)}/>
        <OrderDetailHistory order={order} isMinibarClosed={this.isMinibarClosed}/>
        <MinibarClosedModal hidden={this.state.isMinibarClosedModalHidden} close={this.handleClose}/>
      </div>
    );
  }
}

const OrderDetailSTP = () => {
  const order_finder = Ent.find('order');
  return (state, {match}) => {
    return {
      order: order_finder(state, match.params.number),
      workingHours: workingHours(state),
      orders: session_selectors.unconfirmedShipments(state),
  }};
};
const OrderDetailDTP = {fetchOrder: order_actions.fetchOrder, fetchOrders: session_actions.shipmentsUnconfirmed};
const OrderDetailContainer = connect(OrderDetailSTP, OrderDetailDTP)(OrderDetail);

export default OrderDetailContainer;
