// @flow

import React from 'react';
import I18n from '../../../localization';
import style_sheet from './MinibarClosedModal.module.css';
import { Grid, Modal } from 'semantic-ui-react';

type MinibarClosedModalProps = { hidden: boolean, close: () => void };
const MinibarClosedModal = ({hidden, close}: MinibarClosedModalProps) => {
  if (hidden) return null;
  return (
    <Modal open={!hidden} onClose={close} closeIcon size="mini">
      <Grid centered padded>
        <h3 className={style_sheet.minibarClosedModalHeader}>{I18n.t('ui.modal.minibar_is_closed.title')}</h3>
        <p>{I18n.t('ui.modal.minibar_is_closed.text')}</p>
      </Grid>
    </Modal>
  );
};

export default MinibarClosedModal;
