import {filter, switchMap} from 'rxjs/operators';
import {api} from '../networking';
import createActionsForRequest from '../utils/create_actions_for_request';


export const createLabel = (action$: Object) => {
    const label_response_action$ = action$
      .pipe(
        filter(action => action.type === 'LABEL:CREATE'),
        switchMap(action => {
          return createActionsForRequest(api.createLabel(action.meta), action.type, action.meta);
        }));
    return label_response_action$;
  };

export const getBulkLabels = (action$: Object) => {
  const order_response_action$ = action$
      .pipe(
          filter(action => action.type === 'LABEL:BULK'),
          switchMap(action => {
              return createActionsForRequest(api.getBulkLabels(action.meta), action.type, action.options);
          }));

  return order_response_action$;
};

export const shippingPrice = (action$: Object) => {
  const label_response_action$ = action$
    .pipe(
      filter(action => action.type === 'LABEL:SHIPPING_PRICE'),
      switchMap(action => {
        return createActionsForRequest(api.getShippingPrice(action.meta), action.type, action.meta);
      }));
  return label_response_action$;
};

export const destroyPackage = (action$: Object) => {
  const employee_response_action$ = action$
    .pipe(
      filter(action => action.type === 'LABEL:DESTROY'),
      switchMap(action => {
        return createActionsForRequest(api.destroyPackage(action.payload), action.type, action.meta);
      }));

  return employee_response_action$;
};
