import _ from "lodash";
import style_sheet from './CreateCustomTag.module.css';


const CustomTagTable = ({tags}) => {
    return (
         <>
        {_.map(tags, (tag) => {
            return <span title={tag.name} id={tag} key={tag.name} className={style_sheet.tagDotTable} style={{backgroundColor: tag.color}}></span>
        })}
        </>
        
    )
}

export default CustomTagTable;