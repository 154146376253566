// @flow

import type { Action } from '../store';

export const createCarrierAccount = (data): Action => ({
  type: 'CARRIER_ACCOUNT:CREATE',
  payload: data,
  meta: {}
});

export const destroyCarrierAccount = (carrier_account_id: number): Action => ({
  type: 'CARRIER_ACCOUNT:DESTROY',
  payload: { carrier_account_id }
});


export const fetchCarrierAccounts = (options: Object): Action => ({
  type: 'CARRIER_ACCOUNT:FETCH',
  meta: {
    options
  }
});

export const updateCarrierAccount = (data): Action => ({
  type: 'CARRIER_ACCOUNT:UPDATE',
  payload: data,
  meta: { id: data.id }
});


export const resetNewCarrierConfigurationForm = (data): Action => ({
  type: 'CARRIER_ACCOUNT:RESET_NEW_CARRIER_ACCOUNT_FORM',
  payload: data
});
