import { combineReducers } from 'redux';
import type { Action } from '../store';

export const createLabelSuccessReducer = (state: boolean = false, action: Action) => {
  switch (action.type){
    case 'LABEL:CREATE__SUCCESS':
      return true;
    case 'LABEL:CREATE__ERROR':
    case 'LABEL:CLEAN':
      return false;
    default:
      return state;
  }
};

export const createLabelErrorReducer = (state: Array<string> = [], action: Action) => {
  switch (action.type){
    case 'LABEL:CREATE__ERROR':
        return {...state, error: action.error, message: action.payload?.error?.message};
    case 'LABEL:CREATE__SUCCESS':
    case 'LABEL:CLEAN':
      return {};
    default:
      return state;
  }
};

export const isCreatingReducer = (state: boolean = false, action: Action) => {
  switch (action.type){
    case 'LABEL:CREATE__LOADING':
      return true;
    case 'LABEL:CREATE__SUCCESS':
    case 'LABEL:CREATE__ERROR':
      return false;
    default:
      return state;
  }
};

export const shippingRateReducer = (state: Object = [], action: Action) => {
  switch (action.type){
    case 'LABEL:SHIPPING_PRICE__SUCCESS':
      return action.payload.result;
    case 'LABEL:SHIPPING_PRICE__ERROR':
        return {...state, error: action.error, message: action.payload.error.message};
    default:
      return state;
  }
};

export const successfullyDestroyedReducer = (state: boolean = false, action: Action) => {
  switch (action.type){
    case 'LABEL:DESTROY__SUCCESS':
      return true;
    case 'LABEL:DESTROY__ERROR':
    case 'LABEL:CLEAN':
      return false;
    default:
      return state;
  }
};

export const isDestroyingReducer = (state: boolean = false, action: Action) => {
  switch (action.type){
    case 'LABEL:DESTROY__LOADING':
      return true;
    case 'LABEL:DESTROY__SUCCESS':
    case 'LABEL:DESTROY__ERROR':
    case 'LABEL:CLEAN':
      return false;
    default:
      return state;
  }
};

export type LocalState = {
  // createLabel: Array<string>,
  successfully_created: boolean,
  shippingEstimation: Object,
  is_creating: boolean,
  is_destroying: boolean,
  successfully_destroyed: boolean,
};

const labelReducer = combineReducers({
  // createLabel: createLabelReducer,
  is_creating: isCreatingReducer,
  shippingRate: shippingRateReducer,
  successfully_destroyed: successfullyDestroyedReducer,
  is_destroying: isDestroyingReducer,
  createError: createLabelErrorReducer,
  successfully_created: createLabelSuccessReducer
});

export default labelReducer;