// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ViewOrderButton_viewOrderButton__356Nu {\n  margin: 0;\n  height: 32px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background-color: #222;\n  border-radius: 6px;\n  cursor: pointer;\n}\n\n.ViewOrderButton_viewOrderButton__356Nu:hover {\n  background-color: #444;\n}\n\n.ViewOrderButton_mainText__24d2e {\n  font-size: 12px;\n  font-weight: bold;\n  color: #FFF;\n  margin: 0;\n}\n\n.ViewOrderButton_inline__3WlHQ {\n  width: 130px;\n}\n", "",{"version":3,"sources":["webpack://src/views/shared/ViewOrderButton/ViewOrderButton.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,SAAS;AACX;;AAEA;EACE,YAAY;AACd","sourcesContent":[".viewOrderButton {\n  margin: 0;\n  height: 32px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background-color: #222;\n  border-radius: 6px;\n  cursor: pointer;\n}\n\n.viewOrderButton:hover {\n  background-color: #444;\n}\n\n.mainText {\n  font-size: 12px;\n  font-weight: bold;\n  color: #FFF;\n  margin: 0;\n}\n\n.inline {\n  width: 130px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewOrderButton": "ViewOrderButton_viewOrderButton__356Nu",
	"mainText": "ViewOrderButton_mainText__24d2e",
	"inline": "ViewOrderButton_inline__3WlHQ"
};
export default ___CSS_LOADER_EXPORT___;
