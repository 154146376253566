//@flow

import type { PackagePresets } from './index';

export const PACKAGE_PRESETS_COLUMN_ORDER = ['mobile_summary','bottle_count', 'width', 'height', 'length','weight', 'edit'];

export const packagePresetsTableRow = (package_presets: PackagePresets) => {
  return ({
    package: package_presets.package
  });
};
