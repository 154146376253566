import Table from './Table';
import Header from './Header';
import HeaderCell from './HeaderCell';
import Cell from './Cell';
import Row from './Row';
import Body from './Body';
import Footer from './Footer';

const MBTable = { Table, Header, HeaderCell, Cell, Row, Body, Footer };
export default MBTable;
