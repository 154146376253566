// @flow

import {filter, mergeMap, switchMap} from 'rxjs/operators';
import {api} from '../networking';
import createActionsForRequest from '../utils/create_actions_for_request';

export const fetchAll = (action$: Object) => {
    const order_response_action$ = action$
        .pipe(
            filter(action => action.type === 'ORDER:FETCH_ALL'),
            switchMap(action => {
                return createActionsForRequest(api.fetchAllOrders(action.meta), action.type, action.meta);
            })
        );

    return order_response_action$;
};

export const fetchOrder = (action$: Object) => {
    const order_response_action$ = action$
        .pipe(
            filter(action => action.type === 'ORDER:FETCH_ORDER'),
            mergeMap(action => {
                return createActionsForRequest(api.fetchOrder(action.payload.order_id), action.type, action.meta);
            })
        );

    return order_response_action$;
};

export const fetchKeywords = (action$: Object) => {
    const order_response_action$ = action$
        .pipe(
            filter(action => action.type === 'ORDER:FETCH_KEYWORDS'),
            mergeMap(action => {
                return createActionsForRequest(api.fetchKeywords(action.meta), action.type, action.meta);
            })
        );

    return order_response_action$;
};

export const updateOrder = (action$: Object) => {
    const order_response_action$ = action$
        .pipe(
            filter(action => action.type === 'ORDER:UPDATE_ORDER'),
            switchMap(action => {
                return createActionsForRequest(api.updateOrder(action.payload.order_id, action.payload.params), action.type, action.meta);
            })
        );

    return order_response_action$;
};

export const updateBulkOrder = (action$: Object) => {
    const order_response_action$ = action$
        .pipe(
            filter(action => action.type === 'ORDER:UPDATE_BULK_ORDER'),
            switchMap(action => {
                return createActionsForRequest(api.updateBulkOrder(action.payload), action.type, action.meta);
            })
        );

    return order_response_action$;
};

export const updateBulkOrderPresale = (action$: Object) => {
    const order_response_action$ = action$
        .pipe(
            filter(action => action.type === 'ORDER:UPDATE_BULK_ORDER_PRESALE'),
            switchMap(action => {
                return createActionsForRequest(api.updateBulkOrderPresale(action.payload), action.type, action.meta);
            })
        );

    return order_response_action$;
};

export const updateOrderShipping = (action$: Object) => {
    const order_response_action$ = action$
        .pipe(
            filter(action => action.type === 'ORDER:UPDATE_ORDER_SHIPPING'),
            switchMap(action => {
                return createActionsForRequest(api.updateOrderShipping(action.payload.order_id, action.payload.params), action.type, action.meta);
            })
        );

    return order_response_action$;
};

export const updateOrderPresale = (action$: Object) => {
    const order_response_action$ = action$
        .pipe(
            filter(action => action.type === 'ORDER:UPDATE_ORDER_PRESALE'),
            switchMap(action => {
                return createActionsForRequest(api.updateOrderPresale(action.payload.order_id), action.type, action.meta);
            })
        );

    return order_response_action$;
};

export const fetchActive = (action$: Object) => {
    const order_response_action$ = action$
        .pipe(
            filter(action => action.type === 'ORDER:FETCH_ACTIVE'),
            switchMap(action => {
                const meta_defaulted = {page: 1, ...action.meta};
                return createActionsForRequest(api.fetchActiveOrders(action.meta), 'ORDER:FETCH_ACTIVE', meta_defaulted);
            }));

    return order_response_action$;
};

export const fetchException = (action$: Object) => {
    const order_response_action$ = action$
        .pipe(
            filter(action => action.type === 'ORDER:FETCH_EXCEPTION'),
            switchMap(action => {
                const meta_defaulted = {page: 1, ...action.meta};
                return createActionsForRequest(api.fetchExceptionOrders(action.meta), 'ORDER:FETCH_EXCEPTION', meta_defaulted);
            }));

    return order_response_action$;
};

export const fetchCompleted = (action$: Object) => {
    const order_response_action$ = action$
        .pipe(
            filter(action => action.type === 'ORDER:FETCH_COMPLETED'),
            switchMap(action => {
                return createActionsForRequest(api.fetchCompletedOrders(action.meta), action.type, action.meta);
            }));

    return order_response_action$;
};

export const fetchFuture = (action$: Object) => {
    const order_response_action$ = action$
        .pipe(
            filter(action => action.type === 'ORDER:FETCH_FUTURE'),
            switchMap(action => {
                return createActionsForRequest(api.fetchFutureOrders(action.meta), action.type, action.meta);
            })
        );

    return order_response_action$;
};

export const fetchShipped = (action$: Object) => {
    const order_response_action$ = action$
    .pipe(
        filter(action => action.type === 'ORDER:FETCH_SHIPPED'),
        switchMap(action => {
                return createActionsForRequest(api.fetchShippingOrders(action.meta), action.type, action.meta);
            })
        );

    return order_response_action$;
};


export const fetchPreSale = (action$: Object) => {
    const order_response_action$ = action$
    .pipe(
        filter(action => action.type === 'ORDER:FETCH_PRESALE'),
        switchMap(action => {
                return createActionsForRequest(api.fetchPresaleOrders(action.meta), action.type, action.meta);
            })
        );

    return order_response_action$;
};

export const fetchBackOrder = (action$: Object) => {
    const order_response_action$ = action$
    .pipe(
        filter(action => action.type === 'ORDER:FETCH_BACKORDER'),
        switchMap(action => {
                return createActionsForRequest(api.fetchBackOrders(action.meta), action.type, action.meta);
            })
        );

    return order_response_action$;
};

export const createLabel = (action$: Object) => {
    const order_response_action$ = action$
    .pipe(
        filter(action => action.type === 'ORDER:CREATE_LABEL'),
        switchMap(action => {
                return createActionsForRequest(api.createLabel(action.meta), action.type, action.meta);

            })
        );

    return order_response_action$;
};

export const getPackingSlips = (action$: Object) => {
    const order_response_action$ = action$
        .pipe(
            filter(action => action.type === 'ORDER:MULTI_PACK_SLIPS'),
            switchMap(action => {
                return createActionsForRequest(api.multiPackingSlips(action.meta), action.type, action.options);
            }));

    return order_response_action$;
};