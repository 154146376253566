import { fetchKeywords } from '../../../business/order/actions';
import { isFetchingKeywords, keywords } from '../../../business/order/selectors';
import { getSessionKeywords, saveSessionKeywords } from '../../../business/utils/useKeywords';
import I18n from '../../../localization';
const { useDispatch, useSelector } = require('react-redux')
const _ = require("lodash")
const { useState, useEffect } = require("react");
const { Dropdown } = require("semantic-ui-react")

const SearchSelect = ({updateItems, list}) => {
    const KEYWORDS_LOCAL = "KEYWORDS_".concat(list).toUpperCase()
    const keywordsList = useSelector((state) => keywords(state));
    const itemsSaved = getSessionKeywords(KEYWORDS_LOCAL)
    const [items, setItems] = useState([]);
    const dispatch = useDispatch()
    const isFetching = useSelector((state) => isFetchingKeywords(state))


    const updateItemList = (e, data) => {
        saveSessionKeywords(KEYWORDS_LOCAL, JSON.stringify(data)) 
        if(_.isEmpty(data)){
            setItems([]);
            saveSessionKeywords(KEYWORDS_LOCAL, [])
        }
        let keywordList = _.map(data, (item) => {
            let keyword = JSON.parse(item)
            return {key: keyword.prop, value: keyword.value}
        })
        updateItems(keywordList);
    }

    useEffect(() => {
        let itemList = [];
        if(keywordsList && keywordsList.length > 0){
            _.forEach(keywordsList, (prop) => {
                itemList.push(createNewItemBackend(prop))
            })
        }
        if(itemsSaved){
            _.forEach(itemsSaved, (prop) => {
                const item = JSON.parse(prop)
                itemList.push(createNewItemBackend({key: item.prop, keyword: item.value}))
            })

        }

        setItems(itemList)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keywordsList])
    
    const onSearchList = (data) => {
        if(data.length < 4){
            return;
        }
        dispatch(fetchKeywords(list, {query: data}))
    }
    const createNewItemBackend = (foundItem) => {
        let text =  foundItem.key + ": " + foundItem.keyword;
        return { key: text, text: text, prop: foundItem.key, value: JSON.stringify({prop: foundItem.key, value: foundItem.keyword})};
    }
    return (
        <Dropdown options={items} defaultValue={itemsSaved} placeholder={I18n.t('ui.placeholder.search_shipping')} fluid multiple search selection loading={isFetching} onSearchChange={(event, data) => onSearchList(data.searchQuery)} onChange={(e, data) => updateItemList(e, data.value)}/>
    )
}
export default SearchSelect;