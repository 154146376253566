// @flow

import {filter, switchMap} from 'rxjs/operators';
import {api} from '../networking';
import createActionsForRequest from '../utils/create_actions_for_request';

export const createCarrierAccount = (action$: Object) => {
  const carrier_account_response_action$ = action$
    .pipe(
      filter(action => action.type === 'CARRIER_ACCOUNT:CREATE'),
      switchMap(action => {
        return createActionsForRequest(api.createCarrierAccount({...action.payload}), action.type, action.meta);
      }));

  return carrier_account_response_action$;
};

export const destroyCarrierAccount = (action$: Object) => {
  const carrier_account_response_action$ = action$
    .pipe(
      filter(action => action.type === 'CARRIER_ACCOUNT:DESTROY'),
      switchMap(action => {
        return createActionsForRequest(api.destroyCarrierAccount(action.payload), action.type, action.meta);
      }));

  return carrier_account_response_action$;
};

export const fetchCarrierAccounts = (action$: Object) => {
  const carrier_account_response_action$ = action$
    .pipe(
      filter(action => action.type === 'CARRIER_ACCOUNT:FETCH'),
      switchMap(action => {
        return createActionsForRequest(api.fetchCarrierAccounts(), action.type, action.meta);
      }));

  return carrier_account_response_action$;
};

export const updateCarrierAccount = (action$: Object) => {
  const carrier_account_response_action$ = action$
    .pipe(
      filter(action => action.type === 'CARRIER_ACCOUNT:UPDATE'),
      switchMap(action => {
        return createActionsForRequest(api.updateCarrierAccount(action.payload), action.type, action.meta);
      }));

  return carrier_account_response_action$;
};
