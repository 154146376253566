// @flow

import _ from 'lodash';
import { combineReducers } from 'redux';
import I18n from '../../localization';

import type { Action } from '../store';

const destroy_carrier_account_path = 'custom_tags';

export const listTags = (state: Array<string> = [], action: Action) => {
  switch (action.type){
    case 'CUSTOM_TAG:FETCH__SUCCESS':
      return [...action.payload];
    case 'CUSTOM_TAG:CREATE__SUCCESS':
      return [action.payload, ...state];
    case 'CUSTOM_TAG:DESTROY__SUCCESS':
      const url = action.payload.url.split(destroy_carrier_account_path);
      return _.filter(state, s => s.id !== parseInt(url[1]));
    default:
      return state;
  }
};


export const isFetchingReducer = (state: boolean = false, action: Action) => {
  switch (action.type){
    case 'CUSTOM_TAG:FETCH__LOADING':
      return true;
    case 'CUSTOM_TAG:FETCH__SUCCESS':
    case 'CUSTOM_TAG:FETCH__ERROR':
      return false;
    default:
      return state;
  }
};

export const updatingIdReducer = (state: number = null, action: Action) => {
  switch (action.type){
    case 'CUSTOM_TAG:UPDATE__LOADING':
      return action.meta.id;
    case 'CUSTOM_TAG:UPDATE__SUCCESS':
    case 'CUSTOM_TAG:UPDATE__ERROR':
      return null;
    default:
      return state;
  }
};

export const isCreatingReducer = (state: boolean = false, action: Action) => {
  switch (action.type){
    case 'CUSTOM_TAG:CREATE__LOADING':
      return true;
    case 'CUSTOM_TAG:CREATE__SUCCESS':
    case 'CUSTOM_TAG:CREATE__ERROR':
      return false;
    default:
      return state;
  }
};


export const isDestroyingReducer = (state: boolean = false, action: Action) => {
  switch (action.type){
    case 'CUSTOM_TAG:DESTROY__LOADING':
      return true;
    case 'CUSTOM_TAG:DESTROY__SUCCESS':
    case 'CUSTOM_TAG:DESTROY__ERROR':
      return false;
    default:
      return state;
  }
};


export const isAssigningReducer = (state: boolean = false, action: Action) => {
  switch (action.type){
    case 'CUSTOM_TAG:ASSIGN__LOADING':
    case 'CUSTOM_TAG:UNASSIGN__LOADING':
      return true;
    case 'CUSTOM_TAG:DESTROY__SUCCESS':
    case 'CUSTOM_TAG:ASSIGN__SUCCESS':
    case 'CUSTOM_TAG:UNASSIGN__SUCCESS':
    case 'CUSTOM_TAG:DESTROY__ERROR':
    case 'CUSTOM_TAG:ASSIGN__ERROR':
    case 'CUSTOM_TAG:UNASSIGN__ERROR':
      return false;
    default:
      return state;
  }
};

export const successfullyCreatedReducer = (state: boolean = false, action: Action) => {
  switch (action.type){
    case 'CUSTOM_TAG:CREATE__SUCCESS':
      return true;
    case 'CUSTOM_TAG:CREATE__ERROR':
      return false;
    default:
      return state;
  }
};

export const successfullyDestroyedReducer = (state: boolean = false, action: Action) => {
  switch (action.type){
    case 'CUSTOM_TAG:DESTROY__SUCCESS':
      return true;
    case 'CUSTOM_TAG:DESTROY__ERROR':
      return false;
    default:
      return false;
  }
};

export const successfullyUnassignedReducer = (state: boolean = false, action: Action) => {
  switch (action.type){
    case 'CUSTOM_TAG:UNASSIGN__SUCCESS':
      return true;
    case 'CUSTOM_TAG:UNASSIGN__LOADING':
    case 'CUSTOM_TAG:UNASSIGN__ERROR':
      return false;
    default:
      return state;
  }
};

export const successfullyAssignedReducer = (state: boolean = false, action: Action) => {
  switch (action.type){
    case 'CUSTOM_TAG:ASSIGN__SUCCESS':
      return true;
    case 'CUSTOM_TAG:ASSIGN__LOADING':
    case 'CUSTOM_TAG:ASSIGN__ERROR':
      return false;
    default:
      return state;
  }
};

export const createCustomTagErrorsReducer = (state: Array<string> = [], action: Action) => {
  switch (action.type){
    case 'CUSTOM_TAG:CREATE__SUCCESS':
      return []
    case 'CUSTOM_TAG:CREATE__ERROR':{
      if (action.payload.error.message === 'Internal Server Error'){
        return [I18n.t('form.error.invalid')];
      } else {
        return [action.payload.error.message];
      }
    }
    default:
      return state;
  }
};

export type LocalState = {
  listTags: Array<string>,
  is_fetching: boolean,
  updating_id: number,
  is_creating: boolean,
  is_destroying: boolean,
  is_assigning: boolean,
  successfully_destroyed: boolean,
  createCustomTagErrors: Array<string>,
  successfully_created: boolean,
  successfully_assigned: boolean,
  successfully_unassigned: boolean
};

const customTagReducer = combineReducers({
  listTags: listTags,
  is_fetching: isFetchingReducer,
  updating_id: updatingIdReducer,
  is_creating: isCreatingReducer,
  createCustomTagErrors: createCustomTagErrorsReducer,
  successfully_created: successfullyCreatedReducer,
  is_destroying: isDestroyingReducer,
  is_assigning: isAssigningReducer,
  successfully_destroyed: successfullyDestroyedReducer,
  successfully_assigned: successfullyAssignedReducer,
  successfully_unassigned: successfullyUnassignedReducer
});

export default customTagReducer;
