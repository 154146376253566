// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MBRadioOption_radioButton__2ww9K {\n  font-size: 14px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: #FFF;\n  border: 1px solid #222;\n  border-radius: 14px;\n  cursor: pointer;\n  padding: 4px 12px;\n  margin-right: 6px;\n}\n\n.MBRadioOption_active__cHaon {\n  background-color: #0076CD;\n  border-color: #0076CD;\n  color: #FFF;\n}\n", "",{"version":3,"sources":["webpack://src/views/elements/MBRadioOption/MBRadioOption.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,sBAAsB;EACtB,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,WAAW;AACb","sourcesContent":[".radioButton {\n  font-size: 14px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: #FFF;\n  border: 1px solid #222;\n  border-radius: 14px;\n  cursor: pointer;\n  padding: 4px 12px;\n  margin-right: 6px;\n}\n\n.active {\n  background-color: #0076CD;\n  border-color: #0076CD;\n  color: #FFF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioButton": "MBRadioOption_radioButton__2ww9K",
	"active": "MBRadioOption_active__cHaon"
};
export default ___CSS_LOADER_EXPORT___;
