// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateCustomTag_colorPicker__3dV7B{\n    min-height: 37px;\n    height: 37px;\n    margin-right: 4px;\n    vertical-align: bottom;\n}\n\n.CreateCustomTag_tagDot__1Tsxt {\n    margin-bottom: 0;\n    height: 36px;\n    width: 36px;\n    border-radius: 5%;\n    display: inline-block;\n    vertical-align: bottom;\n}\n\n.CreateCustomTag_tagDotTable__vW71J {\n    margin-bottom: 0;\n    height: 30px;\n    width: 25px;\n    border-radius: 20%;\n    display: inline-block;\n    vertical-align: bottom;\n}\n\n\n.CreateCustomTag_tagText__1Ejv0{\n    margin-left: 9px;\n    margin-bottom: 9px;\n    font-weight: bold;\n    display: inline-block;\n    height: 100%;\n\n}\n\n.CreateCustomTag_errorWrapper__1SL9r {\n    color: red;\n    font-size: 10px;\n    margin-top: 14px;\n  }", "",{"version":3,"sources":["webpack://src/views/shared/OrderTable/CustomTags/CreateCustomTag.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,qBAAqB;IACrB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,qBAAqB;IACrB,sBAAsB;AAC1B;;;AAGA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,qBAAqB;IACrB,YAAY;;AAEhB;;AAEA;IACI,UAAU;IACV,eAAe;IACf,gBAAgB;EAClB","sourcesContent":[".colorPicker{\n    min-height: 37px;\n    height: 37px;\n    margin-right: 4px;\n    vertical-align: bottom;\n}\n\n.tagDot {\n    margin-bottom: 0;\n    height: 36px;\n    width: 36px;\n    border-radius: 5%;\n    display: inline-block;\n    vertical-align: bottom;\n}\n\n.tagDotTable {\n    margin-bottom: 0;\n    height: 30px;\n    width: 25px;\n    border-radius: 20%;\n    display: inline-block;\n    vertical-align: bottom;\n}\n\n\n.tagText{\n    margin-left: 9px;\n    margin-bottom: 9px;\n    font-weight: bold;\n    display: inline-block;\n    height: 100%;\n\n}\n\n.errorWrapper {\n    color: red;\n    font-size: 10px;\n    margin-top: 14px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorPicker": "CreateCustomTag_colorPicker__3dV7B",
	"tagDot": "CreateCustomTag_tagDot__1Tsxt",
	"tagDotTable": "CreateCustomTag_tagDotTable__vW71J",
	"tagText": "CreateCustomTag_tagText__1Ejv0",
	"errorWrapper": "CreateCustomTag_errorWrapper__1SL9r"
};
export default ___CSS_LOADER_EXPORT___;
