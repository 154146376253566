// @flow

import React from 'react';
import {Button, Icon, Image} from 'semantic-ui-react';
import _ from 'lodash';
import classNames from 'classnames/bind';
import {connect} from 'react-redux';
import {goBack, push} from 'connected-react-router';
import I18n from '../../../localization';
import type Order from '../../../business/order';
import {order_actions, order_helpers, order_selectors} from '../../../business/order';
import MBButton from '../../elements/MBButton';
import MBIcon from '../../elements/MBIcon';
import UpdateStateButton from '../../shared/UpdateStateButton';
import ReportProblemButton from './ReportProblemButton';
import style_sheet from './OrderDetail.module.css';
import {OrderAttribute} from '../../shared/OrderAttribute';
import PrintLabelModal from '../../shared/PrintLabelModal/PrintLabelModal';
import { useHistory } from 'react-router-dom';
import { isUnconfirmed, isWaitingForCarrier } from '../../../business/order/helpers';
import ShippingOrderStateModal from '../../shared/ShippingOrderStateModal/ShippingOrderStateModal';

const cx = classNames.bind(style_sheet);

const {
  orderNumber,
  orderAttributes,
  deadlineTime,
  deadlineDate,
  isException,
  isGift,
  isTerminalState,
  isScheduledFor,
  isScheduledSoonNoStateFilter,
  giftMessage,
  deliveryMethodType,
  displayState,
  updateableFromDetail,
  orderStatusClass,
  trackingNumber,
  carrier,
  isShipped,
  isPresale,
  isBackorder
} = order_helpers;

type OrderDetailHeaderProps = { order: Order, isMinibarClosed: () => void };
const OrderDetailHeader = ({order, isMinibarClosed, nextId, prevId, origin}: OrderDetailHeaderProps) => (
  <div>
    <NavigationRow order={order} nextId={nextId} prevId={prevId} origin={origin}/>
    <SummaryRow order={order} isMinibarClosed={isMinibarClosed}/>
    <GiftDetails order={order}/>
  </div>
);

const NavigationRowDTP = {goBackRoute: goBack, pushRoute: push, updateOrderPresale: order_actions.updateOrderPresale};
const connectNavigationRow = connect(null, NavigationRowDTP);



const NavigationRow = connectNavigationRow(({order, nextId, prevId, print_url, pushRoute, updateOrderPresale, origin}) => {
  const history = useHistory();

  const moveOrder = (url) => {
    if(url){
      history.push(url);
    }else{
      history.push('/orders');
    }
  }
  
  return <div className={style_sheet.headerNav}>
    <span onClick={() => {moveOrder(origin)}} className={style_sheet.backToOrderListLink}>
      <Icon name="chevron left"/>
      {I18n.t('ui.link.back_to_order_list')}
    </span>

    <MBButton
        button_type="tertiary"
        disabled={!prevId}
        size="large"
        title={I18n.t('accessibility.title.previous_order')}
        onClick={() => moveOrder(`/order/${prevId}`, {origin: origin})}>
        <Icon name="chevron left"/>
        {I18n.t('ui.button.previous_order')}
      </MBButton>
      
    <MBButton
        button_type="tertiary"
        disabled={!nextId}
        size="large"
        title={I18n.t('accessibility.title.next_order')}
        onClick={() => moveOrder(`/order/${nextId}`, {origin: origin})}>
        {I18n.t('ui.button.next_order')}
        <Icon name="chevron right"/>
    </MBButton>
    <div className={style_sheet.buttonContainer}>
      {(isShipped(order) && !isPresale(order) && !isBackorder(order)) && <PrintLabelModal order={order} />}
      <MBButton
        button_type="tertiary"
        size="large"
        disabled={(isScheduledFor(order) && !isScheduledSoonNoStateFilter(order))}
        title={_.isEmpty(print_url) ? I18n.t('accessibility.title.generating_receipt') : I18n.t('accessibility.title.print_order')}
        onClick={() => {
          window.open(`../pdf_html/${order.id}`, '_newtab');
        }}>
        <MBIcon icon="print" color='mb_black'/>
        {I18n.t('ui.button.print')}
      </MBButton>
      {(isPresale(order) || isBackorder(order)) && 
               <Button
                color='green'
                title={I18n.t('accessibility.title.confirm_presale')}
                onClick={() => updateOrderPresale(order.id)}>
                <span className={style_sheet.buttonPrint}>{I18n.t('ui.button.ready')}</span>
              </Button> }
    </div>
  
  </div>
});


const SummaryRowSTP = (state, {order}) => ({
  hasPendingSubstitutions: (order_selectors.pendingSubstitutionsByOrderId(order.id)(state) || []).length
});
type SummaryRowProps = { order: Order, isMinibarClosed: () => void, hasPendingSubstitutions: boolean };
const SummaryRow = connect(SummaryRowSTP)(({order, isMinibarClosed, hasPendingSubstitutions}: SummaryRowProps) => (
  <div className={style_sheet.summaryRow}>
    <div className={style_sheet.orderTitleWrapper}>
      <div className={style_sheet.flexRow}>
        <h2 className={style_sheet.orderTitle}>{I18n.t('ui.header.order_number', {number: orderNumber(order)})}</h2>
        <OrderAttribute attributes={orderAttributes(order)}
                        showSubstitutionOk={hasPendingSubstitutions || isException(order)}/>
        {order.storefront && 
        <Image
          inline
          src={order.storefront.favicon_url}        
          avatar
          title={order.storefront.name}/>}
      </div>
      <h3 className={cx('orderState', orderStatusClass(order))}>{displayState(order)} {isWaitingForCarrier(order) && <span className={style_sheet.waitingCarrier}> - {I18n.t('accessibility.title.waiting_carrier')}</span>}</h3>
      {(trackingNumber(order)) ? (
        <div className={style_sheet.trackInfo}>
          <p
            className={style_sheet.trackInfoRow}>{I18n.t('ui.header.tracking_number', {tracking_number: trackingNumber(order)})}</p>
          <p className={style_sheet.trackInfoRow}>{I18n.t('ui.header.carrier', {carrier: carrier(order)})}</p>
        </div>
      ) : null}
      <div className={style_sheet.buttonsWrapper}>
        {!hasPendingSubstitutions && <UpdateOrderButton order={order}/>}
        <ReportProblemButton order={order} visible={!isTerminalState(order)} isMinibarClosed={isMinibarClosed}/>
      </div>
    </div>
    <div className={style_sheet.deadlineWrapper}>
      <p
        className={style_sheet.deadlineTitle}>{!isScheduledFor(order) ? I18n.t(`ui.header.deadline.${deliveryMethodType(order)}`) : I18n.t('ui.header.deadline.scheduled')}</p>
      <MBIcon icon={`${deliveryMethodType(order)}_deadline`} size="large" color="mb_dark_grey"/>
      <p className={style_sheet.deadlineTime}>{!isScheduledFor(order) ? deadlineTime(order) : deadlineDate(order)}</p>
      <p className={style_sheet.deadlineDate}>{!isScheduledFor(order) ? deadlineDate(order) : deadlineTime(order)}</p>
    </div>
  </div>
));

const renderButtons = (order) => {
  if(isUnconfirmed(order)){
    return <UpdateStateButton order_id={order.id}/>
  } else {
    if(isShipped(order)){
      return <ShippingOrderStateModal order={order}/>
    }else {
      return <UpdateStateButton order_id={order.id}/>
    }
  }
}

type UpdateOrderButtonProps = { order: Order };
const UpdateOrderButton = ({order}: UpdateOrderButtonProps) => {
  if (!updateableFromDetail(order)) return null;
  return (
    <div className={style_sheet.updateButtonWrapper}>
      {renderButtons(order)}
    </div>
  );
};

// TODO: differentiate text if no gift note
type GiftDetailsProps = { order: Order };
const GiftDetails = ({order}: GiftDetailsProps) => {
  if (!isGift(order)) return null;
  return (
    <div className={style_sheet.giftDetailsWrapper}>
      <p className={style_sheet.giftHeader}>{I18n.t('ui.header.gift_header')}</p>
      <p className={style_sheet.giftMessage}>{giftMessage(order)}</p>
    </div>
  );
};
export default OrderDetailHeader;
