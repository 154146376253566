// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UpdateStateButton_updateStateButton__3Zk1Q {\n  margin: 0;\n  height: 36px;\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background-color: #222;\n  border-radius: 6px;\n  cursor: pointer;\n}\n\n.UpdateStateButton_updateStateButton__3Zk1Q:hover {\n  background-color: #444;\n}\n\n.UpdateStateButton_inlineButton__3ml5a {\n  flex: 0 1;\n  height: 32px;\n  width: 130px;\n}\n\n.UpdateStateButton_inlineMarkAs__33unL {\n  font-size: 8px;\n  color: #FFF;\n  margin: 0;\n}\n\n.UpdateStateButton_inlineNextState__1dL6k {\n  font-size: 12px;\n  color: #FFF;\n  font-weight: bold;\n  margin: 0;\n}\n\n.UpdateStateButton_mainText__1PgTa {\n  font-size: 14px;\n  font-weight: bold;\n  color: #FFF;\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://src/views/shared/UpdateStateButton/UpdateStateButton.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,SAAO;EACP,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,cAAc;EACd,WAAW;EACX,SAAS;AACX;;AAEA;EACE,eAAe;EACf,WAAW;EACX,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,SAAS;AACX","sourcesContent":[".updateStateButton {\n  margin: 0;\n  height: 36px;\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background-color: #222;\n  border-radius: 6px;\n  cursor: pointer;\n}\n\n.updateStateButton:hover {\n  background-color: #444;\n}\n\n.inlineButton {\n  flex: 0;\n  height: 32px;\n  width: 130px;\n}\n\n.inlineMarkAs {\n  font-size: 8px;\n  color: #FFF;\n  margin: 0;\n}\n\n.inlineNextState {\n  font-size: 12px;\n  color: #FFF;\n  font-weight: bold;\n  margin: 0;\n}\n\n.mainText {\n  font-size: 14px;\n  font-weight: bold;\n  color: #FFF;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"updateStateButton": "UpdateStateButton_updateStateButton__3Zk1Q",
	"inlineButton": "UpdateStateButton_inlineButton__3ml5a",
	"inlineMarkAs": "UpdateStateButton_inlineMarkAs__33unL",
	"inlineNextState": "UpdateStateButton_inlineNextState__1dL6k",
	"mainText": "UpdateStateButton_mainText__1PgTa"
};
export default ___CSS_LOADER_EXPORT___;
