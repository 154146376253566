// @flow

import type { OrderState, OrderListName } from './index';
import type { Action } from '../store';


export const fetchOrder = (order_id: string, options: {notify: boolean} = {notify: false}): Action => ({
  type: 'ORDER:FETCH_ORDER',
  payload: { order_id },
  meta: options
});

type UpdateParams = {
  state?: OrderState,
  delivery_estimate_id?: number,
  scheduled_for?: string,
  driver_id?: string,
  signed_by_name?: string,
  exception?: Exception
};


type UpdateOrdersParams = {
  order_id?: number,
  state?: OrderState
};

export const updateOrder = (order_id: string, params: UpdateParams): Action => ({
  type: 'ORDER:UPDATE_ORDER',
  payload: { order_id, params },
  meta: {}
});

export const updateOrders = (orders: Array<UpdateOrdersParams>): Action => ({
  type: 'ORDER:UPDATE_BULK_ORDER',  
  payload: orders,
  meta: {}
});

export const updateOrdersPresale = (orders: Array<number>): Action => ({
  type: 'ORDER:UPDATE_BULK_ORDER_PRESALE',  
  payload: orders,
  meta: {}
});

export const updateOrderShipping = (order_id: string, params: UpdateParams): Action => ({
  type: 'ORDER:UPDATE_ORDER_SHIPPING',
  payload: { order_id, params },
  meta: {}
});

export const updateOrderPresale = (order_id: string): Action => ({
  type: 'ORDER:UPDATE_ORDER_PRESALE',
  payload: { order_id },
  meta: {}
});

//TODO: make options type real

export const makeFetchList = (list_name: OrderListName) => (options: Object): Action => ({
  type: `ORDER:FETCH_${list_name.toUpperCase()}`,
  meta: options
});

export const fetchAll = (options: Object): Action => ({
  type: 'ORDER:FETCH_ALL',
  meta: options
});

export const fetchKeywords = (list: string, query: String): Action => ({
  type: 'ORDER:FETCH_KEYWORDS',
  meta: {list: list, query: query}
});

export const fetchActive = (options: Object): Action => ({
  type: 'ORDER:FETCH_ACTIVE',
  meta: options
});

export const fetchException = (options: Object): Action => ({
  type: 'ORDER:FETCH_EXCEPTION',
  meta: options
});

export const fetchCompleted = (options: Object): Action => ({
  type: 'ORDER:FETCH_COMPLETED',
  meta: options
});

export const fetchFuture = (options: Object): Action => ({
  type: 'ORDER:FETCH_FUTURE',
  meta: options
});

export const fetchShipped = (options: Object): Action => ({
  type: 'ORDER:FETCH_SHIPPED',
  meta: options
});


export const fetchPreSale = (options: Object): Action => ({
  type: 'ORDER:FETCH_PRESALE',
  meta: options
});


export const fetchBackOrder = (options: Object): Action => ({
  type: 'ORDER:FETCH_BACKORDER',
  meta: options
});

export const createLabel = (label: Object): Action => ({
  type: 'ORDER:CREATE_LABEL',
  meta: label
});

export const getPackingSlips = (options: Array): Action => ({
  type: 'ORDER:MULTI_PACK_SLIPS',
  meta: options
});

export const markListsStale = (): Action => ({type: 'ORDER:MARK_LISTS_STALE'});
