import accessibility from './accessibility.json';
import form from './form.json';
import system from './system.json';
import ui from './ui.json';

const internacionalization = {
  accessibility,
  form,
  system,
  ui
};

export default internacionalization;
