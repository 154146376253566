// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrderAttribute_attributeItem__aYUS5 {\n  background-color: #E1E8F7;\n  border-radius: 15px;\n  padding: 3px 12px;\n  margin-left: 3px;\n  margin-right: 3px;\n}\n\n.OrderAttribute_container__3TV9k {\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://src/views/shared/OrderAttribute/OrderAttribute.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".attributeItem {\n  background-color: #E1E8F7;\n  border-radius: 15px;\n  padding: 3px 12px;\n  margin-left: 3px;\n  margin-right: 3px;\n}\n\n.container {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"attributeItem": "OrderAttribute_attributeItem__aYUS5",
	"container": "OrderAttribute_container__3TV9k"
};
export default ___CSS_LOADER_EXPORT___;
