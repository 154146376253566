// @flow

import type { Action } from '../store';

export const createPackagePresets = (data): Action => ({
  type: 'PACKAGE_PRESETS:CREATE',
  payload: data,
  meta: {}
});

export const destroyPackagePresets = (package_preset_id: number): Action => ({
  type: 'PACKAGE_PRESETS:DESTROY',
  payload: { package_preset_id }
});

export const fetchPackagePresets = (options: Object): Action => ({
  type: 'PACKAGE_PRESETS:FETCH',
  meta: {
    options
  }
});

export const updatePackagePresets = (data): Action => ({
  type: 'PACKAGE_PRESETS:UPDATE',
  payload: data,
  meta: { id: data.id }
});


export const resetNewPackagePresetForm = (data): Action => ({
  type: 'PACKAGE_PRESETS:RESET_NEW_PACKAGE_PRESET_FORM',
  payload: data
});
