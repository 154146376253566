// @flow

import React from 'react';
import Break from './Break';
import CarrierConfiguration from './CarrierConfiguration/CarrierConfiguration';
import EmployeeList from './EmployeeList';
import NotificationMethodList from './NotificationMethodList';
import PackagePresets from './PackagePresets/PackagePresets';
import style_sheet from './Settings.module.css';
import Holidays from './Holidays/Holidays';

const Settings = () => (
  <div className={style_sheet.sceneWrapper}>
    <h2>Breaks</h2>
    <Break/>
    <h2>Holidays</h2>
    <Holidays/>
    <h2>Employees</h2>
    <EmployeeList/>
    <h2>Notification Methods</h2>
    <NotificationMethodList/>
    <h2>Carrier Configurations</h2>
    <CarrierConfiguration />
    <h2>Package Presets</h2>
    <PackagePresets />

    
  </div>
);

export default Settings;
