// @flow

import type { Action } from '../store';

export const createCustomTag = (data): Action => ({
  type: 'CUSTOM_TAG:CREATE',
  payload: data,
  meta: {}
});

export const destroyCustomTag = (custom_tag_id: number): Action => ({
  type: 'CUSTOM_TAG:DESTROY',
  payload: { custom_tag_id }
});


export const fetchCustomTags = (options: Object): Action => ({
  type: 'CUSTOM_TAG:FETCH',
  meta: {
    options
  }
});

export const updateCustomTag = (data): Action => ({
  type: 'CUSTOM_TAG:UPDATE',
  payload: data,
  meta: { id: data.id }
});

export const assignCustomTag = (data): Action => ({
  type: 'CUSTOM_TAG:ASSIGN',
  payload: data,
  meta: { }
});

export const unassignCustomTag = (data): Action => ({
  type: 'CUSTOM_TAG:UNASSIGN',
  payload: data,
  meta: {  }
});
