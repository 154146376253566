import style_sheet from './CarrierConfiguration.module.css';
import I18n from '../../../../localization';
import CarrierTypeField from "../../../shared/form_fields/CarrierTypeField";
import { useDispatch, useSelector } from "react-redux";
import { carrier_account_actions } from "../../../../business/carrier_accounts/index";
import { carrier_account_selectors } from "../../../../business/carrier_accounts";
import { useEffect, useState } from "react";
import { Grid, GridColumn, Modal } from "semantic-ui-react";
import CarrierForm from './CarrierConfigurationForm';
import NewFormError from '../NewFormError';
import { resetNewCarrierConfigurationForm } from '../../../../business/carrier_accounts/actions';

const { successfullyCreated } = carrier_account_selectors;

const GLS_US = "gls_us"
const BETTER_TRUCKS = "better_trucks"
const FEDEX = "fedex"
const UPS = "ups"


const CarrierConfigurationModal = ({visible, close}) => {
    const dispatch = useDispatch();
    const [carrierOption, setCarrierOption] = useState("");
    const errors = useSelector((state) => carrier_account_selectors.createCarrierAccountErrors(state));

    const created = useSelector((state) => successfullyCreated(state));
    
    const updateCarrier = (value) => {
        setCarrierOption(value)
    }

    useEffect(() => {
        if(created){
            dispatch(resetNewCarrierConfigurationForm());
            setCarrierOption("");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [created])

    const closeModal = () => {
        dispatch(resetNewCarrierConfigurationForm());
        close();
    }
    
    const createConfiguration = (values) => {
        const {address1, address2, city, state, zip_code, phone, username, password, account_number, api_key, first_name, last_name} = values;
        const carrier = {
            carrier: carrierOption, 
            account_details: {
            },
        };
        if(carrierOption === FEDEX || carrierOption === UPS){
            carrier.account_details.account_number = account_number;
            if(address1 || address2 || city || state || zip_code || phone){
                carrier.account_details = {
                    first_name: first_name, 
                    last_name: last_name, 
                    account_number: account_number
                    
                }
                carrier.account_details.address = {
                    address1: address1,
                    address2: address2,
                    city: city,
                    state_name: state,
                    zip_code: zip_code,
                    phone: phone };
            }
        }

        if(carrierOption === GLS_US){
            carrier.account_details.username = username
            carrier.account_details.password = password
            carrier.account_details.account_number = account_number
        }

        if(carrierOption === BETTER_TRUCKS){
            carrier.account_details.apiKey = api_key
        }

        dispatch(carrier_account_actions.createCarrierAccount(carrier));
    }

    return (
        <Modal open={visible} onClose={() => closeModal()} closeIcon size="mini">
            <Grid centered padded>
                <GridColumn textAlign='center'>
                    <h3 className={style_sheet.modalHeader}>{I18n.t('ui.modal.carrier_configuration.title')}</h3>
                    <CarrierTypeField updateCarrier={updateCarrier}/>
                    
                    <CarrierForm subject={carrierOption} sendCarrier={createConfiguration} />
                    <NewFormError error_messages={errors}/>
                </GridColumn>
            </Grid>
            
        </Modal>
    );
}

export default CarrierConfigurationModal