//@flow

import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Checkbox, Dropdown} from 'semantic-ui-react';
import {SingleDatePicker} from 'react-dates';
import moment from 'moment';
import {report_actions} from '../../../business/report';
import I18n from '../../../localization';
import style_sheet from './Report.module.css';

// TODO: Switch to MBForm Component
// TODO: use MBButton Component with better special snowflake styling options
export class ReportGenerator extends PureComponent {
  props: ReportsProps
  state: ReportsState

  constructor(props: ReportsProps) {
    super(props);
    this.state = {
      open_orders: null,
      shipping_only: null,
      delivery_only: null,
      start_date: null,
      end_date: null,
      report_type: null,
      focused_field: ''
    };
  }

  // TODO: put in I18n and un-stub
  stubData = [
    {
      text: 'Select Report type',
      value: null
    },
    {
      text: 'Orders',
      value: 'ORDERS'
    },
    {
      text: 'Driver Tips',
      value: 'TIPS'
    },
    {
      text: 'Itemized Orders',
      value: 'ITEMIZED_ORDERS'
    },
    {
      text: 'Adjustments',
      value: 'ADJUSTMENTS'
    }
  ]

  isBeforeStart = date => date.isBefore(this.state.start_date)
  isAfterEnd = date => date.isAfter(this.state.end_date)

  render() {
    return (
      <div className={style_sheet.generatorWrapper}>
        
        <Checkbox className={style_sheet.reportCheckbox} id='open_orders' onChange={(e, data) => this.setState({open_orders: data.checked})} checked={this.state.open_orders} label={I18n.t('ui.placeholder.open_orders')} />
        <Checkbox id='shipping_orders'  className={style_sheet.reportCheckbox} onChange={(e, data) => this.setState({shipping_only: data.checked, delivery_only: data.checked ? false : this.state.delivery_only})} checked={this.state.shipping_only} label={I18n.t('ui.placeholder.shipping_only')} />
        <Checkbox id='delivery_orders' className={style_sheet.reportCheckbox} onChange={(e, data) => this.setState({delivery_only: data.checked, shipping_only: data.checked ? false : this.state.shipping_only})} checked={this.state.delivery_only} label={I18n.t('ui.placeholder.delivery_only')} />
        
        <Dropdown
          placeholder="Select Report Type"
          onChange={(e, type) => this.setState({report_type: type.value})}
          selection
          options={this.stubData}
          className={style_sheet.reportTypeSelect}/>
        <SingleDatePicker
          date={this.state.start_date}
          onDateChange={date => this.setState({focused_field: '', start_date: date})}
          focused={this.state.focused_field === 'start_date'}
          onFocusChange={({focused}) => {
            this.setState({focused_field: focused ? 'start_date' : ''});
          }}
          numberOfMonths={1}
          placeholder={I18n.t('ui.placeholder.start_date')}
          anchorDirection="right"
          isOutsideRange={date => date.isAfter(moment().add(1, 'days'))}
          isDayBlocked={this.isAfterEnd}
          showDefaultInputIcon
          hideKeyboardShortcutsPanel/>
        <SingleDatePicker
          date={this.state.end_date}
          onDateChange={date => this.setState({focused_field: '', end_date: date})}
          focused={this.state.focused_field === 'end_date'}
          onFocusChange={({focused}) => {
            this.setState({focused_field: focused ? 'end_date' : ''});
          }}
          numberOfMonths={1}
          placeholder={I18n.t('ui.placeholder.end_date')}
          anchorDirection="right"
          isDayBlocked={this.isBeforeStart}
          isOutsideRange={date => date.isAfter(moment().add(1, 'days'))}
          showDefaultInputIcon
          hideKeyboardShortcutsPanel/>
        <div
          className={style_sheet.generateButton}
          onClick={() => {
            if (this.state.report_type === null) {
              // alert('Please select a type!');
              return;
            }
            this.props.createReport({
              report_type: this.state.report_type,
              start_date: this.state.start_date ? this.state.start_date._d : Date(),
              end_date: this.state.end_date ? this.state.end_date._d : Date(),
              open_orders: this.state.open_orders,
              shipping_only: this.state.shipping_only,
              delivery_only: this.state.delivery_only
            });
            this.setState({report_type:  'ORDERS', start_date: null, end_date: null, focused_field: '', open_orders: false, shipping_only: false, delivery_only: false});
          }}>
          {I18n.t('ui.button.generate_report')}
        </div>
      </div>
    );
  }
}

const ReportGeneratorDTP = {
  createReport: report_actions.createReport
};
const ReportGeneratorContainer = connect(null, ReportGeneratorDTP)(ReportGenerator);

export default ReportGeneratorContainer;
