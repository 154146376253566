//@flow

import { globalizeSelectors } from '../utils/globalizeSelectors';
import type { LocalState } from './reducer';

const LOCAL_PATH = 'settings';

// local selectors
export const workingHours = (state: LocalState) => state.working_hours;

// global selectors
const selectors = {
  ...globalizeSelectors(LOCAL_PATH, {
    workingHours
  })
};

export default selectors;
