// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrderStateModal_modalFormWrapper__1UE1r {\n  display: flex;\n  flex-direction: column;\n}\n\n.OrderStateModal_fieldLabel__1BG02 {\n  align-self: flex-start;\n  text-align: left;\n  margin-bottom: 10px;\n}\n\n.OrderStateModal_formMessage__Zjm-3 {\n  align-self: flex-start;\n  text-align: left;\n  color: #6F6F6F;\n}\n\n.OrderStateModal_question__1kQiI {\n  margin-top: 0;\n  padding: 0 10px;\n}", "",{"version":3,"sources":["webpack://src/views/shared/OrderStateModal/OrderStateModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB","sourcesContent":[".modalFormWrapper {\n  display: flex;\n  flex-direction: column;\n}\n\n.fieldLabel {\n  align-self: flex-start;\n  text-align: left;\n  margin-bottom: 10px;\n}\n\n.formMessage {\n  align-self: flex-start;\n  text-align: left;\n  color: #6F6F6F;\n}\n\n.question {\n  margin-top: 0;\n  padding: 0 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalFormWrapper": "OrderStateModal_modalFormWrapper__1UE1r",
	"fieldLabel": "OrderStateModal_fieldLabel__1BG02",
	"formMessage": "OrderStateModal_formMessage__Zjm-3",
	"question": "OrderStateModal_question__1kQiI"
};
export default ___CSS_LOADER_EXPORT___;
