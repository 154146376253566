//@flow

import { globalizeSelectors } from '../utils/globalizeSelectors';
import type { LocalState } from './reducer';

const LOCAL_PATH = 'carrier_account';

// local selectors
export const listAccounts = (state: LocalState) => state.listAccounts;
export const estado = (state: LocalState) => state;
export const createCarrierAccountErrors = (state: LocalState) => state.createCarrierAccountErrors;
export const isFetching = (state: LocalState) => state.is_fetching;
export const updatingId = (state: LocalState) => state.updating_id;
export const isCreating = (state: LocalState) => state.is_creating;
export const successfullyCreated = (state: LocalState) => state.successfully_created;

const selectors = {
  ...globalizeSelectors(LOCAL_PATH, {
    listAccounts,
    createCarrierAccountErrors,
    isFetching,
    updatingId,
    isCreating,
    successfullyCreated
  })
};

// global selectors
export default selectors;