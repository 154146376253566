import I18n from '../../../localization';
import style_sheet from './OrderDetail.module.css';
import _ from 'lodash'
import { Button, Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { destroyPackage } from '../../../business/label/actions';
import { isDestroying, successfullyDestroyed } from '../../../business/label/selectors';
import { useEffect } from 'react';
import { fetchOrder } from '../../../business/order/actions';


const PackageList = ({packages, order}) => {
    var internal_packages = packages;

    const dispatch = useDispatch();

    const destroying = useSelector((state) => isDestroying(state));

    const destroyed = useSelector((state) => successfullyDestroyed(state));
    useEffect(() => {
        if(destroyed){
            dispatch(fetchOrder(order));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [destroyed])

    const labels =  _.filter(internal_packages, p => p.label_url !== null || p.tracking_url !== null);

    const deletePackage = (pack_id) => {
        dispatch(destroyPackage(order, pack_id))
    }

    return (
        <div className={style_sheet.infoItem}>
            <div className={style_sheet.sectionHeader}><p className={style_sheet.sectionTitle}>{I18n.t('ui.header.package_list')}</p></div>
            <div className={style_sheet.infoItemContents}>
                {!_.isEmpty(labels) && labels.map((pack, key) => {
                    return  <div className={style_sheet.packageItem} key={key}>
                                Package {key}
                                <Button icon loading={destroying} floated='right' onClick={() => deletePackage(pack.id)}> <Icon name='trash' color='red'></Icon></Button>
                                <Button icon loading={destroying} floated='right' onClick={() => window.open(pack.tracking_url, '_newtab')}> <Icon name='truck' color='blue'></Icon></Button>
                                {pack.label_url && <Button icon loading={destroying} floated='right' onClick={() => window.open(pack.label_url, '_newtab')}> <Icon name='eye' color='blue'></Icon></Button>}
                            </div>   
                })}
            </div>
        </div>
    )
}

export default PackageList;