import style_sheet from './PrintLabelModal.module.css';
import I18n from '../../../localization';
import { Button, Form, FormGroup, FormInput, FormSelect, Grid, GridColumn, GridRow, Header, Icon, Label, List, ListItem, Loader, Message, Modal, ModalActions, ModalContent, ModalHeader, Popup } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { confirmationOptions } from './LabelData'
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { carrier_account_selectors } from '../../../business/carrier_accounts';
import { calculateShippingPrice, cleanLabel, createLabel, getBulkLabels } from '../../../business/label/actions';
import { createError, estimationDone, successfullyCreated } from '../../../business/label/selectors';
import { isCreating } from '../../../business/label/selectors';
import { Link } from 'react-router-dom';
import { listPackages } from '../../../business/package_presets/selectors';

//Component to print label for one 'order' or multiple 'orders'. When order is fullfilleld its become one order print label component otherwise it is multiple order.
const PrintLabelModal = ({order, orders}) => {

    //hooks
    const [multiLabels, setMultiLabels] = useState([]);
    const [visible, setVisible] = useState(false);

    //Label represent the Order Label, with many possible Shipments. Each Shipment has one or more package.
    const [currentLabel, setCurrentLabel] = useState({})

    const [currentShipment, setCurrentShipment] = useState({});
    const [currentPack, setCurrentPack] = useState({
        dimensions: { length: 0, width: 0, height: 0},
        weight: { value: 0, unit: 'pound' }
    });

    const [packages, setPackages] = useState([]);
    const [packagePosition] = useState(0);

    const [customerName, setCustomerName] = useState('');
    const [shippingTo, setShippingTo] = useState('');
    const [bottleCount, setBottleCount] = useState('');
    const [deliveryExpectation] = useState('');
    const [orderList, setOrderList] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [fetchingShippingPrice, setFetchingShippingPrice] = useState(false)
    const priceReturn = useSelector((state) => estimationDone(state));
    const [rateEstimation, setRateEstimation] = useState({});
    const packageList = useSelector((state) => listPackages(state.packagePreset));

    //dispatch
    const dispatch = useDispatch();

    const creating = useSelector((state) => isCreating(state));
    const createDone = useSelector((state) => successfullyCreated(state));
    const error = useSelector((state) => createError(state));
    const carrierAccounts = useSelector((state) => carrier_account_selectors.listAccounts(state));

    useEffect(() => {
        if(priceReturn){
            setRateEstimation(priceReturn);
            setFetchingShippingPrice(false);
            if(!priceReturn.error){
                setCurrentShipment({...currentShipment, shippingPrice: "US$ " + priceReturn.shipping_amount?.amount});
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceReturn])

    useEffect(() => {
        setServiceList(_.map(carrierAccounts, carrier => {
           return { 'text': carrier.carrier,
                    'key': carrier.carrier,
                    'value': carrier.carrier}
        }));
    },[carrierAccounts]);

    const handleCreateLabel = () => {
        dispatch(createLabel({shipments: multiLabels}));
    }

    useEffect(() => {
        if(order){
            setMultiLabels([
                {
                    uuid: order.id,
                    packages: [{
                        confirmation: 'none',
                        shippingPrice: 'US$ 0.00',
                        carrier: carrierAccounts[0]?.carrier,
                        validPack: false,
                        package: getPackagePreset(order)
                    }]
                }]
            );
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    useEffect(() => {
        setMultiLabels(_.map(orders, o => {
            //Create new label for each order. Garantee that all labels has basic informations.
            return {
                    uuid: o.id,
                    packages: [{
                        confirmation: 'none',
                        carrier: carrierAccounts[0]?.carrier,
                        shippingPrice: 'US$ 0.00',
                        validPack: false,
                        package: getPackagePreset(o)
                    }]
                }
            }));
        setOrderList(orders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    } ,[orders]);

    const getPackagePreset = (order) => {
        const count = _.sumBy(order.order_items, 'quantity');
        const basePreset = {
            dimensions: { length: 0, width: 0, height: 0},
            weight: { value: 0, unit: 'pound' }
        }
        let packagePreset = _.find(packageList, p => p.bottle_count === count)
        return packagePreset ? packagePreset : basePreset;
    }

    useEffect(() => {
        validateShipments(packages);
        setCurrentLabel({...currentLabel, packages: packages});
        if(!_.isUndefined(currentLabel) && !_.isEmpty(multiLabels)){
            var index = _.findIndex(multiLabels, ['uuid', currentLabel.uuid]);
            multiLabels.splice(index, 1, currentLabel);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [packages]);

    useEffect(() =>{
        setPackages(packages.splice(packagePosition, 1, currentShipment));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentShipment]);


    useEffect(() => {
        setCurrentShipment({...currentShipment, package: currentPack});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPack]);

    const closeModal = () =>{
        setVisible(!visible);
    }

    const validateShipments = () => {
        _.forEach(currentLabel.packages, (p) => {
            p.validPack =  !_.isNil(currentShipment.carrier) &&
            !_.isNil(currentShipment.confirmation) && validatePackage(p.package);
        })
    }

    const validatePackage = (pack) => {
        return (pack.dimensions.height > 0 && pack.dimensions.width > 0 && pack.dimensions.length > 0)
        && pack.weight.value > 0;
    }
    const getRateEstimation = () => {
        if(!_.isNil(currentShipment.carrier) &&
            !_.isNil(currentShipment.confirmation) && validatePackage(currentPack)){
            setFetchingShippingPrice(true);
            dispatch(calculateShippingPrice(currentLabel.uuid, {carrier: currentShipment.carrier, dimensions: currentPack.dimensions, weight: currentPack.weight}));
        }
    }

    const handleClickOrder = (selectedLabel) => {
        const actualLabel = _.find(multiLabels, l => l.uuid === selectedLabel.id);
        if(actualLabel){
            setCurrentLabel(actualLabel);
            setCurrentShipment(actualLabel.packages[0]);
            setCurrentPack(actualLabel.packages[0].package);
            setRateEstimation();
            updateOrderInfos(selectedLabel);
        }
    }

    const isValidShipment = (selectedLabel) => {
        const actualLabel = _.find(multiLabels, l => l.uuid === selectedLabel.id);
        return _.find(actualLabel.packages, p => p.validPack);
    }

    const updateOrderInfos = (selectedLabel) => {
        if(!_.isNil(selectedLabel.address)){
            setShippingTo(selectedLabel.address.city + ', ' + selectedLabel.address.state);
        }
        setBottleCount(_.sumBy(selectedLabel.order_items, 'quantity').toString() + ' Bottles');
        setCustomerName(selectedLabel.customer_name);
    }

    const validateLabels = () => {
        let valid = true;
        _.forEach(multiLabels, l => {
            valid = _.find(l.packages, p => !p.validPack) ? false : valid;
        });
        return valid;
    }

    const showModal = () => {
        if(isEnable()){
            dispatch(cleanLabel())
            handleClickOrder(order ? order : orderList[0]);
            setVisible(!visible);
        }
    }

    const isEnable = () => {
       return !_.isEmpty(carrierAccounts) && (!_.isEmpty(orders) || !_.isEmpty(order));
    }

    const handlePrintLabels = () => {
        let params  = []
        if(_.isEmpty(order)) {
            params = _.map(orders, 'id');
        } else{
            params.push(order.id);
        }
        console.log("=== handlePrintLabels 2");
        console.log(params);
        if(params){
            const prepare_params = 'shipment_ids[]='+ params.join('&shipment_ids[]=');
            dispatch(getBulkLabels(prepare_params));
        }
    }

    return (
        <Modal open={visible} trigger={<Button as='div'
            loading={creating}
            labelPosition='right'
            onClick={() => showModal()} >
                <Label as='a' basic color={isEnable() ? 'blue' : 'grey'} pointing='right'>
                    {I18n.t('ui.button.create_label')}
                </Label>
                <Popup trigger={<Button basic loading={creating} color={isEnable() ? 'blue' : 'grey'}>
                    <Icon name='help' />
                </Button>} flowing hoverable >
                    <Grid centered>
                        <GridColumn textAlign='center'>
                            <Header as='h4'>{I18n.t('ui.label_info.title')}</Header>
                            <List>
                                <ListItem>
                                    {I18n.t('ui.label_info.setup')}<Link to={'/settings'} title="here">here</Link>
                                </ListItem>
                                <ListItem>
                                    {I18n.t('ui.label_info.choose_carrier')}
                                </ListItem>
                                <ListItem>
                                    {I18n.t('ui.label_info.packages')}
                                </ListItem>
                                <ListItem>
                                    {I18n.t('ui.label_info.confirmation')}
                                </ListItem>
                                <ListItem>
                                    {I18n.t('ui.label_info.estimated_rates')} <Icon name='refresh'/>
                                </ListItem>
                            </List>
                        </GridColumn>
                    </Grid>
                </Popup>
            </Button>} onClose={closeModal} closeIcon centered={false} dimmer='inverted'>
            <ModalHeader className={style_sheet.centered}>
                <div>
                    <h2 className={style_sheet.title}>{I18n.t('ui.modal.print_label.title')}</h2>
                    {order && <span className={style_sheet.subtitle}>{I18n.t('ui.modal.print_label.subtitle')}</span>}
                    {orders && <span className={style_sheet.subtitle}>{I18n.t('ui.modal.print_label.change_order_details')}</span>}

                </div>
            </ModalHeader>

            <ModalContent>
                <Loader inverted disabled={!fetchingShippingPrice} />
                <Grid>
                    <GridRow>
                        <GridColumn>
                            {rateEstimation && rateEstimation.error && <Message error={rateEstimation.error} hidden={!rateEstimation.error}>{rateEstimation.message}</Message>}
                            {error && <Message error={error.error} hidden={!error.error}>{error.message}</Message>}
                            {createDone && <Message success >
                                <span>{I18n.t('ui.modal.print_label.labelSuccess')}</span>
                                <Button onClick={() => handlePrintLabels()}><Icon name='print' /></Button>
                            </Message>}

                        </GridColumn>
                    </GridRow>
                    <GridRow>
                        {orders && <GridColumn width={5}>
                            <GridRow className={style_sheet.sectionTitle}>
                                <GridColumn>
                                    <h3>{I18n.t('ui.modal.print_label.orders')}</h3>
                                </GridColumn>
                            </GridRow>
                            <GridRow className={style_sheet.ordersContainer}>
                                {orderList && orderList.map(o => {
                                    return (
                                            <GridColumn key={`column_` + o.id }  className={style_sheet.orderButtonContainer}>
                                                <Button width="100%" icon basic disabled={fetchingShippingPrice}  color={o.id === currentLabel.uuid ? 'black' : 'grey'} key={o.id} onClick={() => handleClickOrder(o)}> {isValidShipment(o) ? <Icon name='check circle' size='large' color='green' /> : <Icon name='clock' size='large' />} Order #{o.number}</Button>
                                            </GridColumn>
                                        )
                                })}
                            </GridRow>
                        </GridColumn>}



                        <GridColumn width={order ? 16 : 11}>
                            <Grid columns={2}>
                                <GridRow className={style_sheet.sectionTitle}>
                                    <GridColumn width={7}>
                                        <h3>{I18n.t('ui.modal.print_label.ship_form')}</h3>
                                    </GridColumn>
                                    <GridColumn width={9}>
                                        <h3>{I18n.t('ui.modal.print_label.ship_info')}</h3>
                                    </GridColumn>
                                </GridRow>
                            </Grid>
                            <Form>
                                <FormGroup>
                                    <FormInput className={style_sheet.opacity} width={7} disabled label={I18n.t('ui.modal.print_label.customer_name')} fluid value={customerName}></FormInput>
                                    <FormSelect width={9} label={I18n.t('ui.modal.print_label.service')} value={currentShipment.carrier} onChange={(e, data) => setCurrentShipment({...currentShipment, carrier: data.value})} options={serviceList}></FormSelect>
                                </FormGroup>
                                <FormGroup>
                                    <FormInput width={7} className={style_sheet.opacity} disabled label={I18n.t('ui.modal.print_label.shipping_to')} fluid value={shippingTo}></FormInput>
                                    <FormInput fluid type="number" min="0" max="50" width={3} label={I18n.t('ui.modal.print_label.length')} value={currentPack.dimensions.length} onChange={(e, data) => setCurrentPack({...currentPack, dimensions: {...currentPack.dimensions, length: data.value}})}></FormInput>
                                    <span className={style_sheet.dimensionsLabel}>x</span>
                                    <FormInput fluid type="number" min="0" max="50" width={3} label={I18n.t('ui.modal.print_label.width')}  value={currentPack.dimensions.width} onChange={(e, data) => setCurrentPack({...currentPack, dimensions: {...currentPack.dimensions, width: data.value}})}></FormInput>
                                    <span className={style_sheet.dimensionsLabel}>x</span>
                                    <FormInput fluid type="number" min="0" max="50" width={3} label={I18n.t('ui.modal.print_label.height')} value={currentPack.dimensions.height} onChange={(e, data) => setCurrentPack({...currentPack, dimensions: {...currentPack.dimensions, height: data.value}})}></FormInput>
                                </FormGroup>
                                <FormGroup>
                                    <FormInput width={7} className={style_sheet.opacity} disabled label={I18n.t('ui.modal.print_label.bottle_count')} fluid value={bottleCount}></FormInput>
                                    <FormInput width={9} labelPosition='right' label={I18n.t('ui.modal.print_label.package_weight')} fluid value={currentPack.weight.value} onChange={(e, data) => setCurrentPack({...currentPack, weight: {...currentPack.weight, value: data.value}})}>
                                        <input type='Number' />
                                        <Label>lbs</Label>
                                    </FormInput>
                                </FormGroup>
                                <FormGroup>
                                    <FormInput width={7} className={style_sheet.opacity} disabled label={I18n.t('ui.modal.print_label.delivery_expectation')} fluid value={deliveryExpectation}></FormInput>
                                    <FormSelect width={9} label={I18n.t('ui.modal.print_label.confirmation')} value={currentShipment.confirmation} onChange={(e, data) => setCurrentShipment({...currentShipment, confirmation: data.value})} options={confirmationOptions}></FormSelect>
                                </FormGroup>
                            </Form>
                        </GridColumn>
                    </GridRow>
                </Grid>

                <p className={style_sheet.priceLabel}>{I18n.t('ui.modal.print_label.estimated')}{currentShipment.carrier}{I18n.t('ui.modal.print_label.shipping')}{currentShipment.shippingPrice} <Icon link name='refresh' onClick={() => getRateEstimation()} color='black' size="small"/></p>
            </ModalContent>

            <ModalActions className={style_sheet.centered}>
                 <Button
                    basic
                    color='black'
                    loading={creating}
                    title={I18n.t('accessibility.title.close')}
                    onClick={closeModal}>
                    {I18n.t('ui.button.close')}
                </Button>
                <Button
                    button_type="active"
                    primary
                    loading={creating}
                    disabled={!validateLabels()}
                    title={I18n.t('accessibility.title.create_label')}
                    onClick={() => handleCreateLabel()}>
                    {I18n.t('ui.button.create_label')}
                </Button>
            </ModalActions>

        </Modal>
    )
}

export default PrintLabelModal
