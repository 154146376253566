//@flow
import _ from 'lodash';
import { createSelector } from 'reselect';
import { globalizeSelectors } from '../utils/globalizeSelectors';
import type { LocalState } from './reducer';
import type { OrderListName } from './index';

const LOCAL_PATH = 'order';

// local selectors
export const orderList = (state: LocalState, list_name: OrderListName) => state[list_name];

export const orderAll = (state: LocalState) => state;

export const orderById = (state: LocalState) => (id: string) => {
  return state[LOCAL_PATH].by_id[id];
};

export const isUpdatingBulk = (state: LocalState) => state.order.bulk_updating;
export const isBulkUpdated = (state: LocalState) => state.order.bulk_updated;

export const isFetching = (state: LocalState) => state.is_fetching;
export const isFetchingPresale = (state: LocalState) => state.order.is_fetchingPresale;
export const isUpdating = (state: LocalState) => state.is_updating;
export const isUpdatingPresale = (state: LocalState) => state.order.presale_update;
export const updatingResult = (state: LocalState) => state.order?.updatingResult;
export const updatingShippingResult = (state: LocalState) => state.order?.updatingShipping;
export const keywords = (state: LocalState) => state.order?.keywords;
export const isFetchingKeywords = (state: LocalState) => state.order?.is_fetching_keywords;


export const substitutionsByOrderId = (id: string) => createSelector(
  (state) => orderById(state)(id),
  (order) => {
    return (order || {}).substitutions || [];
  }
);

export const substitutionsByOrderItemId = (orderId: string, orderItemId: number) =>
  createSelector(
    substitutionsByOrderId(orderId),
    (substitutions) => _.filter(substitutions, ({original_id}) => original_id === orderItemId)
  );

export const pendingSubstitutionsByOrderItemId = (orderId: integer, orderItemId: integer) =>
  createSelector(
    substitutionsByOrderItemId(orderId, orderItemId),
    (substitutions) => _.filter(substitutions, ({ status }) => _.includes(['pending'], status))
  );

export const pendingSubstitutionsByOrderId = (orderId: string) => (state: LocalState) => {
  const substitutions = substitutionsByOrderId(orderId)(state);
  return _.filter(substitutions, ({ status }) => _.includes(['pending'], status));
};


// global selectors
const selectors = {
  ...globalizeSelectors(LOCAL_PATH, {
    orderList,
    orderAll,
    orderById,
    isFetching,
    isFetchingPresale,
    isUpdating,
    substitutionsByOrderId,
    updatingResult,
    updatingShippingResult,
    keywords,
    isFetchingKeywords,
    isBulkUpdated,

  }),
  pendingSubstitutionsByOrderId
};

export default selectors;