import { useState } from "react";
import MBButton from "../../../elements/MBButton"
import MBIcon from "../../../elements/MBIcon";
import I18n from '../../../../localization';
import CarrierConfigurationModal from "./CarrierConfigurationModal";

const NewCarrierConfigurationButton = () => {
    const [visible, setVisible] = useState(false);
    const closeModal = () =>{
        setVisible(false);
    }

    return (
        <div>
            <MBButton
                title={I18n.t('accessibility.title.add_carrier_configuration')}
                button_type="link"
                size="large"
                onClick={() => setVisible(true)}>
                <MBIcon icon="add" color="mb_red" size="small" />
                <div>{I18n.t('ui.button.new_carrier_configuration')}</div>
            </MBButton>
            <CarrierConfigurationModal visible={visible} close={closeModal}/>

        </div>
        

       
    )
}

export default NewCarrierConfigurationButton;