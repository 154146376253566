// @flow

import type { Action } from '../store';

export const createHoliday = (data): Action => ({
  type: 'HOLIDAY:CREATE',
  payload: data,
  meta: {}
});

export const destroyHoliday = (holiday_id: number): Action => ({
  type: 'HOLIDAY:DESTROY',
  payload: { holiday_id }
});

export const fetchHolidays = (options: Object): Action => ({
  type: 'HOLIDAY:FETCH',
  meta: {
    options
  }
});

export const resetNewHolidayForm = (data): Action => ({
  type: 'HOLIDAY:RESET_NEW_HOLIDAY_FORM',
  payload: data
});
