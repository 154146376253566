// @flow

import _ from 'lodash';
import { combineReducers } from 'redux';
import I18n from '../../localization';

import type { Action } from '../store';

const destroy_carrier_account_path = 'supplier/carrier_accounts/';

export const listAccounts = (state: Array<string> = [], action: Action) => {
  switch (action.type){
    case 'CARRIER_ACCOUNT:FETCH__SUCCESS':
      return [...action.payload];
      case 'CARRIER_ACCOUNT:CREATE__SUCCESS':
        return [action.payload, ...state];
      case 'CARRIER_ACCOUNT:DESTROY__SUCCESS':
        const url = action.payload.url.split(destroy_carrier_account_path);
        return _.filter(state, s => s.id !== parseInt(url[1]));
    default:
      return state;
  }
};


export const isFetchingReducer = (state: boolean = false, action: Action) => {
  switch (action.type){
    case 'CARRIER_ACCOUNT:FETCH__LOADING':
      return true;
    case 'CARRIER_ACCOUNT:FETCH__SUCCESS':
    case 'CARRIER_ACCOUNT:FETCH__ERROR':
      return false;
    default:
      return state;
  }
};

export const updatingIdReducer = (state: number = null, action: Action) => {
  switch (action.type){
    case 'CARRIER_ACCOUNT:UPDATE__LOADING':
      return action.meta.id;
    case 'CARRIER_ACCOUNT:UPDATE__SUCCESS':
    case 'CARRIER_ACCOUNT:UPDATE__ERROR':
      return null;
    default:
      return state;
  }
};

export const isCreatingReducer = (state: boolean = false, action: Action) => {
  switch (action.type){
    case 'CARRIER_ACCOUNT:CREATE__LOADING':
      return true;
    case 'CARRIER_ACCOUNT:CREATE__SUCCESS':
    case 'CARRIER_ACCOUNT:CREATE__ERROR':
      return false;
    default:
      return state;
  }
};

export const successfullyCreatedReducer = (state: boolean = false, action: Action) => {
  switch (action.type){
    case 'CARRIER_ACCOUNT:CREATE__SUCCESS':
      return true;
    case 'CARRIER_ACCOUNT:RESET_NEW_CARRIER_ACCOUNT_FORM':
    case 'CARRIER_ACCOUNT:CREATE__ERROR':
      return false;
    default:
      return state;
  }
};

export const createCarrierAccountErrorsReducer = (state: Array<string> = [], action: Action) => {
  switch (action.type){
    case 'CARRIER_ACCOUNT:CREATE__ERROR':{
      if (action.payload.error.message === 'Internal Server Error'){
        return [I18n.t('form.error.invalid')];
      } else {
        return [action.payload.error.message];
      }
    }
    case 'CARRIER_ACCOUNT:RESET_NEW_CARRIER_ACCOUNT_FORM':
      return {};
    default:
      return state;
  }
};

export type LocalState = {
  listAccounts: Array<string>,
  is_fetching: boolean,
  updating_id: number,
  is_creating: boolean,
  createCarrierAccountErrors: Array<string>,
  successfully_created: boolean
};

const carrierAccountReducer = combineReducers({
  listAccounts: listAccounts,
  is_fetching: isFetchingReducer,
  updating_id: updatingIdReducer,
  is_creating: isCreatingReducer,
  createCarrierAccountErrors: createCarrierAccountErrorsReducer,
  successfully_created: successfullyCreatedReducer
});

export default carrierAccountReducer;
