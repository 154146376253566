import React from 'react';
import _ from 'lodash';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { MBForm, MBFormSubmitButton } from '../../../elements/MBForm';
import style_sheet from './CarrierConfiguration.module.css';
import I18n from '../../../../localization';
import { NameField } from '../../../shared/form_fields';
import { connect } from 'react-redux';

const carrier_subject_forms = {
    gls_us: (subject, sendCarrier) => (
        <CarrierFormWrapper subject={subject} sendCarrier={sendCarrier} >
            <NameField name="username" />

            <NameField name="password" />

            <NameField name="account_number"/>
        </CarrierFormWrapper>
    ),
    ups: (subject, sendCarrier) => (
        <CarrierFormWrapper subject={subject} sendCarrier={sendCarrier} >
            <NameField name="account_number"/>

            <NameField name="first_name"/>

            <NameField name="last_name"/>

            <NameField name="address1"/>

            <NameField is_required={false} name="address2"/>

            <NameField name="city"/>

            <NameField name="state"/>

            <NameField name="zip_code"/>

            <NameField name="phone"/>
        </CarrierFormWrapper>
    ),
    fedex: (subject, sendCarrier) => (
        <CarrierFormWrapper subject={subject} sendCarrier={sendCarrier} >
            <NameField name="account_number"/>

            <NameField name="first_name"/>

            <NameField name="last_name"/>

            <NameField name="address1"/>

            <NameField is_required={false} name="address2"/>

            <NameField name="city"/>

            <NameField name="state"/>

            <NameField name="zip_code"/>

            <NameField name="phone"/>
        </CarrierFormWrapper>
    ),
    better_trucks: (subject, sendCarrier) => (
        <CarrierFormWrapper subject={subject} sendCarrier={sendCarrier} >
            <NameField name="api_key"/>
        </CarrierFormWrapper>
    ),
    
};

const CarrierFormWrapperDTP = {};
const connectCarrierFormWrapper = connect(null, CarrierFormWrapperDTP);

type CarrierFormWrapperProps = {
    subject: string,
    sendCarrier: () => void,
    children: any
  };
  const CarrierFormWrapper = connectCarrierFormWrapper(({
                                                    subject,
                                                    sendCarrier,
                                                    children
                                                  }: CarrierFormWrapperProps) => {
    const handleSubmit = values => {
      sendCarrier(values);
    };
    return (
      <ReactCSSTransitionGroup
        className={style_sheet.formWrapper}
        transitionAppear
        transitionAppearTimeout={700}
        transitionName={style_sheet}
        transitionEnterTimeout={700}
        transitionLeaveTimeout={700}>
        <MBForm
          key={subject}
          onSubmit={handleSubmit}
          validateSubmittable={fields => _.some(fields, 'value')}
          className={style_sheet.carrierForm}>
          {children}
          <MBFormSubmitButton key="submit" text={I18n.t('ui.modal.help.send')}/>
        </MBForm>
      </ReactCSSTransitionGroup>
    );
  });
  
type CarrierFormProps = { subject: string, sendCarrier: () => void };
const CarrierForm = ({subject, sendCarrier}: CarrierFormProps) => {
if (!subject) return null;
    return carrier_subject_forms[subject](subject, sendCarrier);
};
export default CarrierForm;