import React, { useEffect, useState } from 'react'
import style_sheet from './ShippingListWrapper.module.css'
import I18n from '../../../localization';
import { isFetching, isUpdatingBulk, orderAll } from '../../../business/order/selectors';
import * as Ent from '@minibar/store-business/src/utils/ent';
import { listIds, listNextPage, listTotalCount, listTotalPages } from '../../../business/order/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShipped } from '../../../business/order/actions';
import ShippingFiltersModal from '../../shared/ShippingFilterModal/ShippingFiltersModal';
import ShippingTable from '../../shared/ShippingTable/ShippingTable';
import _ from 'lodash';
import MBTable from '../../elements/MBTable';
import ShippingMultiOptions from '../../shared/ShippingOptionsModal/ShippingMultiOptions';
import SearchSelect from '../../shared/SearchSelect/SearchSelect';
import AppliedFilters from '../../shared/OrderList/AppliedFilters';
import { successfullyAssigned, successfullyUnassigned } from '../../../business/custom_tags/selectors';
import { getSessionKeywords } from '../../../business/utils/useKeywords';

const baseSearchFilters = {
  filters: {keywords: []},
  sort_column: "created_at",
  sort_direction: "desc",
  per_page: 25,
  page: 1
}

const KEYWORDS_SHIPPING = "KEYWORDS_SHIPPING"

const ShippingListWrapper = () => {
  
  //Hooks
  const dispatch = useDispatch();
  const fetching = useSelector((state) => isFetching(state.order));

  const [appliedFilters, setAppliedFilters] = useState({});
  const [isFiltersModalVisible, setIsFiltersModalVisible] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const assigned = useSelector((state) => successfullyAssigned(state));
  const unassigned = useSelector((state) => successfullyUnassigned(state));
  const updating = useSelector((state) => isUpdatingBulk(state));
  
  const [nextPage, setNextPage] = useState(1)
  const [totalCount, setTotalCount] = useState()
  const [totalPages, setTotalPages] = useState(0)
  const [searchFilter, setSearchFilter] = useState(baseSearchFilters)

  useEffect(() => {
    if(assigned){
      dispatch(fetchShipped(searchFilter))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assigned])

  useEffect(() => {
    if(unassigned){
      dispatch(fetchShipped(searchFilter))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unassigned])

  //Selectors
  const orderBase = useSelector((state) => orderAll(state));

  useEffect(() => {
    const itemsSaved = getSessionKeywords(KEYWORDS_SHIPPING)
    let keywordList = []
    _.forEach(itemsSaved, (prop) => {
      const item = JSON.parse(prop)
      keywordList.push({key: item.prop, value: item.value})
  })
  
    dispatch(fetchShipped({...searchFilter, filters: {...searchFilter.filters, keywords: keywordList}}))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const itemsSaved = getSessionKeywords(KEYWORDS_SHIPPING)
    let keywordList = []
    _.forEach(itemsSaved, (prop) => {
      const item = JSON.parse(prop)
      keywordList.push({key: item.prop, value: item.value})
    })
  
    dispatch(fetchShipped({...searchFilter, filters: {...searchFilter.filters, ...appliedFilters, keywords: keywordList}}))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilters]);

  useEffect(() => {
    if(orderBase.order.shipped){
      const findOrders = Ent.find('order');
      const list = findOrders(orderBase, listIds(orderBase.order.shipped));
      setTotalCount(listTotalCount(orderBase.order.shipped))
      setTotalPages(listTotalPages(orderBase.order.shipped))
      setNextPage(listNextPage(orderBase.order.shipped))
      setOrders(list);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBase.order.shipped, appliedFilters])

  const isFilterPopulated = () => {
    return appliedFilters.quantity || appliedFilters.status;
  }

  const handleApplyFilters = (modalFilters) => {
    setAppliedFilters(modalFilters);
    setIsFiltersModalVisible(false);
  }

  const selectRow = (id) => {
    const idExist = _.find(selectedOrders, o => o.id === id);
    //If the order is already selected took it out of the list
    if(idExist){
      setSelectedOrders(_.filter(selectedOrders, o => o.id !== id));
    } else {
      //if the order is not selected add the order to the list
      setSelectedOrders(selectedOrders.concat(_.find(orders, o => o.id === id)));
    }
  }

  const selectAllRow = (selectAll) => {
    if(selectAll){
      setSelectedOrders(orders)
    }else{
      setSelectedOrders([]);
    }
  }

  const updateFilterItems = (items) => {
    setSearchFilter({...searchFilter, filters: {...searchFilter.filters, keywords: items}})
    dispatch(fetchShipped({...searchFilter, filters: {...searchFilter.filters, keywords: items}}))
  }

  const attemptNextPageFetch = () => {
    dispatch(fetchShipped({...searchFilter, page: listNextPage(orderBase.order.shipped)}))
  }

  const clearFilters = () => {
    setAppliedFilters({});
  }

  const removeFilter = (type, value) => {
    setAppliedFilters(_.omit(appliedFilters, type));
  }

  //Prepare the filters to use the component and show them.
  const appliedFiltersShippingArray = () => {
    if (_.isEmpty(appliedFilters)){
      return [];
    } 
    let cleanFilters = _.omitBy(appliedFilters, _.isEmpty);
    let modalFilters = _.cloneDeep(cleanFilters);
    
    return _.map(_.toPairs(modalFilters), (value) => ({type: value[0], value: value[1]}));
  };

  return (
    <div>
      <div key="search_wrapper" className={style_sheet.actionRow}>
        <SearchSelect updateItems={updateFilterItems} list="shipping"/>
        <ShippingFiltersModal
          filters={appliedFilters}
          are_applied_filters={isFilterPopulated()}
          handleApplyFilters={handleApplyFilters}
          visible={isFiltersModalVisible}
          toggleVisibility={() => setIsFiltersModalVisible(!isFiltersModalVisible)} />
      </div>
      <ShippingMultiOptions selectedOrders={selectedOrders} confirmOrder={true} createLabel={true} pickList={true} print={true}/>
      <AppliedFilters
          key="applied_filters"
          filters={appliedFiltersShippingArray()}
          is_fetching={fetching}
          removeFilter={removeFilter}
          clearFilters={clearFilters}/>
      {orders && <div className={style_sheet.totalOrders}>Total Orders: {totalCount}</div>}
      <ShippingTable orders={orders} selectRow={selectRow} selectAllRow={selectAllRow} selectedOrders={selectedOrders} multiSelect={true} origin='shipping'/>
      <MBTable.Footer 
          is_fetching={fetching}
          is_empty={_.isEmpty(orders)}
          has_more={nextPage <= totalPages}
          loadMore={attemptNextPageFetch}
          empty_message={I18n.t('ui.table.empty.shipping')}/>
    </div>
  )
}

export default ShippingListWrapper;