// @flow

export type CustomTag = {
  id: number,
  name: string,
  description: string,
  color: string
};



export * as custom_tag_epics from './epics';
export * as custom_tag_actions from './actions';
export { default as customTagReducer } from './reducer';
export { default as custom_tag_selectors } from './selectors';
