export const PickListStyle = {
    page:{
        backgroundColor: "white",
        color: "black"
    },
    viewer: {
        width: "100%",
        height: "500px"
    },
    title: {
        fontWeight: "700",
        textAlign: "center",
        fontFamily: "Open Sans",
        marginTop: "20px",
        marginBottom: "10px"
        
    },
    table: {
        border: "2px solid black", 
        marginLeft: "20px",
        marginRight: "20px"
    },
    tableTitle: {
        flexDirection: "row",
        padding: "7px 7px 7px 7px",
        display: "block",
        borderBottom: "2px solid black", 
        
    },
    productList: {
        fontFamily: "Open Sans",
        fontWeight: "700",
        fontSize: 15,
        
    },
    time: {
        textAlign: "right" ,
        fontFamily: "Open Sans",
        fontWeight: "bold",
        fontSize: 8,
        padding: "7px 7px 7px 7px",
        
    },
    tableHeader:{
        fontFamily: "Open Sans",
        fontWeight: "500",
        fontSize: 11,
        padding: "3px 3px 3px 3px",
        borderBottom: "2px solid black", 
        flexDirection: "row",
        
    },
    itemList: {
        flexDirection: "row",
        borderBottom: "2px solid black", 
    },
    item: {
        fontFamily: "Open Sans",
        fontWeight: "400",
        fontSize: 9,
        padding: "3px 3px 3px 3px",
    },
    total: {
        fontFamily: "Open Sans",
        fontWeight: "700",
        fontSize: 11,
        padding: "10px 10px 10px 10px", 
        textAlign: "right"
    }

    
}