// @flow
import React, { useState } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames/bind';
import _ from 'lodash';
import style_sheet from './Holiday.module.css';
import NewFormError from '../NewFormError';
import I18n from '../../../../localization';
import {holiday_selectors} from '../../../../business/holiday';
import {MBForm, MBFormSubmitButton} from '../../../elements/MBForm';
import moment from 'moment';
import { DayPickerRangeController } from 'react-dates';
import { START_DATE, END_DATE } from 'react-dates/constants';
import { createHoliday, resetNewHolidayForm } from '../../../../business/holiday/actions';


const cx = classNames.bind(style_sheet);

const HolidayForm = ({close}) => {
  

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(START_DATE);
  const [initialMonth] = useState(moment());
  const dispatch = useDispatch();

  const errors = useSelector((state) => holiday_selectors.createHolidayErrors(state));
  const successfully_created = useSelector((state) => holiday_selectors.successfullyCreated(state));
  const isLoading = useSelector((state) => holiday_selectors.isFetching(state));
  
  if (successfully_created) {
    close();
  }
  
  const handleDatesChange = ({ startDate, endDate }) => {
    setFocusedInput(focusedInput === START_DATE ? END_DATE : START_DATE)
    setStartDate(startDate);
    endDate ? setEndDate(endDate) : setEndDate(startDate);
  };

  return (
    <MBForm
      onSubmit={(values) => {
        dispatch(resetNewHolidayForm());
        
        const holiday = {
          holiday :{
            start_date: startDate,
            end_date: endDate
          }
        }
        dispatch(createHoliday(holiday));
        
      }}
      className={cx('newForm', {shake: !_.isEmpty(errors)})}>
        
        <DayPickerRangeController
              onDatesChange={handleDatesChange}
              focusedInput={focusedInput || START_DATE}
              startDate={startDate}
              endDate={endDate}
              numberOfMonths={1}
              hideKeyboardShortcutsPanel
              initialVisibleMonth={() => initialMonth}
            />
      <MBFormSubmitButton
        is_loading={isLoading}
        text={I18n.t('ui.modal.holiday.submit')}/>
      <NewFormError error_messages={errors}/>
    </MBForm>
  );
};

export default HolidayForm;