//@flow

import type { Holidays } from './index';

export const HOLIDAYS_COLUMN_ORDER = ['mobile_summary','date', 'edit'];

export const holidaysTableRow = (holidays: Holidays) => {
  return ({
    holidays: holidays.holiday
  });
};
