//@flow

import { globalizeSelectors } from '../utils/globalizeSelectors';
import type { LocalState } from './reducer';

const LOCAL_PATH = 'label';

// local selectors
export const isCreating = (state: LocalState) => state.label.is_creating;
export const successfullyCreated = (state: LocalState) => state.label.successfully_created;
export const estimationDone = (state: LocalState) => state.label.shippingRate;
export const isDestroying = (state: LocalState) => state.label.is_destroying;
export const successfullyDestroyed = (state: LocalState) => state.label.successfully_destroyed;
export const createError = (state: LocalState) => state.label.createError;


// global selectors
const selectors = {
  ...globalizeSelectors(LOCAL_PATH, {
    isCreating,
    estimationDone,
    isDestroying,
    successfullyDestroyed,
    createError,
    successfullyCreated
    
  })
};

export default selectors;