// @flow
import {filter, switchMap} from 'rxjs/operators';
import {api} from '../networking';
import createActionsForRequest from '../utils/create_actions_for_request';

export const createPackagePresets = (action$: Object) => {
  const package_presets_response_action$ = action$
    .pipe(
      filter(action => action.type === 'PACKAGE_PRESETS:CREATE'),
      switchMap(action => {
        return createActionsForRequest(api.createPackagePresets({...action.payload}), action.type, action.meta);
      }));

  return package_presets_response_action$;
};

export const destroyPackagePresets = (action$: Object) => {
  const package_presets_response_action$ = action$
    .pipe(
      filter(action => action.type === 'PACKAGE_PRESETS:DESTROY'),
      switchMap(action => {
        return createActionsForRequest(api.destroyPackagePresets(action.payload), action.type, action.meta);
      }));

  return package_presets_response_action$;
};

export const fetchPackagePresetss = (action$: Object) => {
  const package_presets_response_action$ = action$
    .pipe(
      filter(action => action.type === 'PACKAGE_PRESETS:FETCH'),
      switchMap(action => {
        return createActionsForRequest(api.fetchPackagePresetss(), action.type, action.meta);
      }));

  return package_presets_response_action$;
};

export const updatePackagePresets = (action$: Object) => {
  const package_presets_response_action$ = action$
    .pipe(
      filter(action => action.type === 'PACKAGE_PRESETS:UPDATE'),
      switchMap(action => {
        return createActionsForRequest(api.updatePackagePresets(action.payload), action.type, action.meta);
      }));

  return package_presets_response_action$;
};
