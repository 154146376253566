import { BlobProvider, Document, Font, Page, PDFDownloadLink, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Icon, Modal, ModalActions, ModalContent } from "semantic-ui-react";
import I18n from '../../../localization';
import style_sheet from './PickListModal.module.css';
import { PickListStyle } from "./PickListStyle";


const PickListModal = ({orders}) => {
    const [items, setItems] = useState([]);
    
    useEffect(() => {
        let allItems= [];
        //Get all items to group by and sum after
        _.forEach(orders, (order) => {
            _.forEach(order.order_items, (item) => {
                allItems = allItems.concat(
                    {key:item.name+item.volume, name: item.name, sku: item.sku, 
                    volume: item.volume, order: order.number, quantity: item.quantity, price: item.unit_price});
            });
        });
        //Group by and sum the quantity
        setItems(_(allItems)
            .groupBy('key')
            .map((item, key) => ({
                name: item[0].name,
                sku: item[0].sku,
                volume: item[0].volume,
                price: "US$ " + item[0].price,
                order: item.length > 1 ? '(Multiple)': item[0].order,
                quantity: _.sumBy(item, 'quantity')
            })).value()
        );
    }, [orders]);


    const PrintListDocument = () => (
        <Document>
        <Page size="A4">
            <View style={styles.title}>
                <Text>{I18n.t('ui.picklist_modal.preview')}</Text>
            </View>

            <View style={styles.table}>
                <View style={styles.tableTitle} >
                    <Text style={[{width: "50%"}, styles.productList]}>{I18n.t('ui.picklist_modal.product_picklist')}</Text>
                    <Text style={[{width: "50%"}, styles.time]}>{timePrint}</Text>
                </View>
                <View style={styles.tableHeader}>
                    <Text style={{width: "40%", marginLeft: "30px"}}>{I18n.t('ui.picklist_modal.item')}</Text>
                    <Text style={{width: "20%"}}>{I18n.t('ui.picklist_modal.order')}</Text>
                    <Text style={{width: "15%"}}>{I18n.t('ui.picklist_modal.sku')}</Text>
                    <Text style={{width: "15%"}}>{I18n.t('ui.picklist_modal.price')}</Text>
                    <Text style={{width: "10%"}}>{I18n.t('ui.picklist_modal.size')}</Text>
                    <Text style={{width: "15%", textAlign: "center"}}>{I18n.t('ui.picklist_modal.required')}</Text>
                </View>
                {items && items.map((item, key) => {
                    return (
                        <View style={styles.itemList} key={item.key}>
                            <Text style={[styles.item, {width: "40%", marginLeft: "30px"}]}>{item.name}</Text>
                            <Text style={[styles.item, {width: "20%"}]}>{item.order}</Text>
                            <Text style={[styles.item, {width: "15%"}]}>{item.sku}</Text>
                            <Text style={[styles.item, {width: "15%"}]}>{item.price}</Text>
                            <Text style={[styles.item, {width: "10%"}]}>{item.volume}</Text>
                            <Text style={[styles.item, {width: "15%", textAlign: "center", fontWeight: "700"}]}>{item.quantity}</Text>
                        </View>
                    )
                })}
                <Text style={styles.total}>
                {I18n.t('ui.picklist_modal.total_items')} {totalItems}
                </Text>
                
            </View>

        </Page>
    </Document>
    )
    
    const styles = StyleSheet.create(PickListStyle);

    Font.register({
        family: 'Open Sans',
        fonts: [
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 500 },
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 }
        ]
    });


    //Hooks
    const [visible, setVisible] = useState(false);
    const timePrint = moment().format('dddd, MMMM D YYYY HH:mmA');
    const [totalItems, setTotalItems] = useState(0);

    useEffect(() => {
        let sum = 0;
        orders.map(o => sum += _.sumBy(o.order_items, 'quantity'));
        setTotalItems(sum);
        
    }, [orders])

    



    return (
        <Modal open={visible} trigger={<Button
            button_type="active"
            secondary
            disabled={_.isEmpty(orders)}
            title={I18n.t('accessibility.title.create_pick_list')}
            onClick={() => setVisible(true)}>
            <Icon name='plus' />
            <span className={style_sheet.buttonText}>{I18n.t('ui.button.create_pick_list')}</span>
        </Button>} onClose={() => setVisible(false)} closeIcon centered={false}>
            <ModalContent height="1000px">
                <PDFViewer style={styles.viewer}>
                    <PrintListDocument />
                </PDFViewer>
            </ModalContent>
            <ModalActions>
            <PDFDownloadLink document={<PrintListDocument />} className="ui primary button" fileName="picklist.pdf">
                {({ blob, url, loading, error }) => (loading ? 'Loading...' : 'Download')}
            </PDFDownloadLink>
            <BlobProvider  document={<PrintListDocument  />}>
            {({ blob, url, loading, error }) => {
                // Do whatever you need with blob here
                return (
                    <a className="ui primary button" rel="noreferrer"  href={url} target="_blank">
                      Print
                    </a>
                  );
            }}
            </BlobProvider>
            </ModalActions>
        </Modal>
    )
}


export default PickListModal;