export const serviceOptions = [
    {"text": "UPS", "key": "ups", "value": "UPS"},
    {"text": "FedEx", "key": "fedex", "value": "FedEx"},
    {"text": "GLS", "key": "gls", "value": "GLS"},
    {"text": "LSO", "key": "lso", "value": "LSO"}];
export const packageSizeOptions = [
    {"text": "0", "key": 0, "value": 0},
    {"text": "1", "key": 1, "value": 1},
    {"text": "2", "key": 2, "value": 2},
    {"text": "3", "key": 3, "value": 3},
    {"text": "4", "key": 4, "value": 4},
    {"text": "5", "key": 5, "value": 5},
    {"text": "6", "key": 6, "value": 6},
    {"text": "7", "key": 7, "value": 7},
    {"text": "8", "key": 8, "value": 8},
    {"text": "9", "key": 9, "value": 9},
    {"text": "10", "key": 10, "value": 10}
];

export const packageWeightMeasure = [
    {"text": "lbs", "key": "lbs", "value": "lbs"},
];

export const confirmationOptions = [
    {"text": "none", "key": "none", "value": "none"},
    {"text": "Delivery", "key": "delivery", "value": "delivery"},
    {"text": "Signature", "key": "signature", "value": "signature"},
    {"text": "Adult Signature", "key": "adult_signature", "value": "adult_signature"},
    {"text": "Direct Signature", "key": "direct_signature", "value": "direct_signature"},
    {"text": "Delivery Mailed", "key": "delivery_mailed", "value": "delivery_mailed"}
];
export const stateOptions = [
    {"text": "Alaska", "key": "AK", "value": "AK"},
    {"text": "Alabama", "key": "AL", "value": "AL"},
    {"text": "Arizona", "key": "AZ", "value": "AZ"},
    {"text": "Arkansas", "key": "AR", "value": "AR"},
    {"text": "California", "key": "CA", "value": "CA"},
    {"text": "Colorado", "key": "CO", "value": "CO"},
    {"text": "Connecticut", "key": "CT", "value": "CT"},
    {"text": "Delaware", "key": "DE", "value": "DE"},
    {"text": "District of Columbia", "key": "DC", "value": "DC"},
    {"text": "Florida", "key": "FL", "value": "FL"},
    {"text": "Georgia", "key": "GA", "value": "GA"},
    {"text": "Hawaii", "key": "HI", "value": "HI"},
    {"text": "Idaho", "key": "ID", "value": "ID"},
    {"text": "Illinois", "key": "IL", "value": "IL"},
    {"text": "Indiana", "key": "IN", "value": "IN"},
    {"text": "Iowa", "key": "IA", "value": "IA"},
    {"text": "Kansas", "key": "KA", "value": "KA"},
    {"text": "Kentucky", "key": "KY", "value": "KY"},
    {"text": "Louisiana", "key": "LA", "value": "LA"},
    {"text": "Maine", "key": "ME", "value": "ME"},
    {"text": "Maryland", "key": "MD", "value": "MD"},
    {"text": "Massachusetts", "key": "MA", "value": "MA"},
    {"text": "Michigan", "key": "MI", "value": "MI"},
    {"text": "Minnesota", "key": "MN", "value": "MN"},
    {"text": "Mississipi", "key": "MS", "value": "MS"},
    {"text": "Missouri", "key": "MO", "value": "MO"},
    {"text": "Montana", "key": "MT", "value": "MT"},
    {"text": "Nebraska", "key": "NE", "value": "NE"},
    {"text": "Nevada", "key": "NV", "value": "NV"},
    {"text": "New Hampshire", "key": "NH", "value": "NH"},
    {"text": "New Jersey", "key": "NJ", "value": "NJ"},
    {"text": "New Mexico", "key": "NM", "value": "NM"},
    {"text": "New York", "key": "NY", "value": "NY"},
    {"text": "North Carolina", "key": "NC", "value": "NC"},
    {"text": "North Dakota", "key": "ND", "value": "ND"},
    {"text": "Ohio", "key": "OH", "value": "OH"},
    {"text": "Oklahoma", "key": "OK", "value": "OK"},
    {"text": "Oregon", "key": "OR", "value": "OR"},
    {"text": "Pennsylvania", "key": "PA", "value": "PA"},
    {"text": "Rhode Island", "key": "RI", "value": "RI"},
    {"text": "South Carolina", "key": "SC", "value": "SC"},
    {"text": "South Dakota", "key": "SD", "value": "SD"},
    {"text": "Tennessee", "key": "TN", "value": "TN"},
    {"text": "Texas", "key": "TX", "value": "TX"},
    {"text": "Utah", "key": "UT", "value": "UT"},
    {"text": "Vermont", "key": "VT", "value": "VT"},
    {"text": "Virginia", "key": "VA", "value": "VA"},
    {"text": "Washington", "key": "WA", "value": "WA"},
    {"text": "West Virginia", "key": "WV", "value": "WV"},
    {"text": "Wisconsin", "key": "WI", "value": "WI"},
    {"text": "Wyoming", "key": "WY", "value": "WY"}
];
