// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShippingMultiOptions_buttonText__5gtLX{\n  margin-left: 5px;\n}\n\n.ShippingMultiOptions_buttonPrint__fGqiO{\n  margin-left: 5px;\n  margin-right: 5px;\n}\n\n.ShippingMultiOptions_buttonContainer__O1NQc{\n  margin: 0 0em 1em 0;\n}\n\n", "",{"version":3,"sources":["webpack://src/views/shared/ShippingOptionsModal/ShippingMultiOptions.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".buttonText{\n  margin-left: 5px;\n}\n\n.buttonPrint{\n  margin-left: 5px;\n  margin-right: 5px;\n}\n\n.buttonContainer{\n  margin: 0 0em 1em 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonText": "ShippingMultiOptions_buttonText__5gtLX",
	"buttonPrint": "ShippingMultiOptions_buttonPrint__fGqiO",
	"buttonContainer": "ShippingMultiOptions_buttonContainer__O1NQc"
};
export default ___CSS_LOADER_EXPORT___;
