import React, { useEffect, useState } from 'react'
import style_sheet from './PreSaleWrapper.module.css'
import I18n from '../../../localization';
import { isFetchingPresale, orderAll } from '../../../business/order/selectors';
import * as Ent from '@minibar/store-business/src/utils/ent';
import { listIds, listNextPage, listTotalCount, listTotalPages } from '../../../business/order/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBackOrder, fetchPreSale, updateOrdersPresale } from '../../../business/order/actions';
import ShippingFiltersModal from '../../shared/ShippingFilterModal/ShippingFiltersModal';
import ShippingTable from '../../shared/ShippingTable/ShippingTable';
import _ from 'lodash';
import AppliedFilters from '../../shared/OrderList/AppliedFilters';
import MBTable from '../../elements/MBTable';
import SearchSelect from '../../shared/SearchSelect/SearchSelect';
import { getSessionKeywords } from '../../../business/utils/useKeywords';
import ShippingMultiOptions from '../../shared/ShippingOptionsModal/ShippingMultiOptions';

const KEYWORDS_PRESALE = "KEYWORDS_PRE_SALE"
const KEYWORDS_BACKORDER = "KEYWORDS_BACK_ORDER"

const PreSaleWrapper = ({isPreSale}) => {

  const searchFilters = {
    filters: {keywords: []},
    sort_column: 'created_at',
    sort_direction: 'desc',
    per_page: 25,
    page: 1,
    state: isPreSale ? 'presale' : 'backorder'
  }

  //Hooks
  const dispatch = useDispatch();

  const fetching = useSelector((state) => isFetchingPresale(state));
  const [queryDone, setQueryDone] = useState("");
  const [appliedFilters, setAppliedFilters] = useState({});
  const [isAppliedFilters, setIsAppliedFilters] = useState(false);
  const [isFiltersModalVisible, setIsFiltersModalVisible] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [nextPage, setNextPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [searchFilter, setSearchFilter] = useState(searchFilters)
  const orderBase = useSelector((state) => orderAll(state));
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);

  useEffect(() => {
    const findOrders = Ent.find('order');
    setOrders(findOrders(orderBase, listIds(isPreSale ? orderBase.order.presale : orderBase.order.back)));
    setTotalCount(listTotalCount(isPreSale ? orderBase.order.presale : orderBase.order.back))
    setNextPage(listNextPage(isPreSale ? orderBase.order.presale : orderBase.order.back))
    setTotalPages(listTotalPages(isPreSale ? orderBase.order.presale : orderBase.order.back))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBase.order.back, orderBase.order.presale])

  const fetchList = () => {
        isPreSale ? dispatch(fetchPreSale({...searchFilter, page: nextPage})) : dispatch(fetchBackOrder({...searchFilter, page: nextPage}))
  }

  useEffect(() => {
  const itemsSaved = getSessionKeywords(isPreSale ? KEYWORDS_PRESALE : KEYWORDS_BACKORDER)
  let keywordList = []
  _.forEach(itemsSaved, (prop) => {
    const item = JSON.parse(prop)
    keywordList.push({key: item.prop, value: item.value})
  })

  isPreSale ? dispatch(fetchPreSale({...searchFilter, filters: {...searchFilter.filters, keywords: keywordList}})) : dispatch(fetchBackOrder({...searchFilter, filters: {...searchFilter.filters, keywords: keywordList}}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const attemptNextPageFetch = () => {
    fetchList()
  }

  const updateFilterItems = (items) => {
    setSearchFilter({...searchFilter, filters: {...searchFilter.filters, keywords: items}})
    dispatch(isPreSale ? fetchPreSale({...searchFilter, filters: {...searchFilter.filters, keywords: items}}) : fetchBackOrder({...searchFilter, filters: {...searchFilter.filters, keywords: items}}))
  }

  const isFilterPopulated = () => {
    return _.isEmpty(appliedFilters.quantity) && _.isEmpty(appliedFilters.amount) && _.isEmpty(appliedFilters.days) && _.isEmpty(appliedFilters.hours) && _.isEmpty(appliedFilters.brand) && _.isEmpty(appliedFilters.status);
  }

  const handleApplyFilters = (modalFilters) => {
    setAppliedFilters(modalFilters);
    setIsAppliedFilters(!isFilterPopulated());
    setIsFiltersModalVisible(false);
  }
    
  const clearFilters = () => {
    setQueryDone("");
    setAppliedFilters({});
    setIsAppliedFilters(false);
  }

  const removeFilter = (type, value) => {
   setAppliedFilters(_.omit(appliedFilters, type));
   if(type === 'query'){
    setQueryDone("");
   }
  }

  const selectRow = (id) => {
    const idExist = _.find(selectedOrders, o => o.id === id);
    //If the order is already selected took it out of the list
    if(idExist){
      setSelectedOrders(_.filter(selectedOrders, o => o.id !== id));
    } else {
      //if the order is not selected add the order to the list
      setSelectedOrders(selectedOrders.concat(_.find(orders, o => o.id === id)));
    }
  }

  const selectAllRow = (selectAll) => {
    if(selectAll){
      setSelectedOrders(orders)
    }else{
      setSelectedOrders([]);
    }
  }

  const confirmAllOrders = () => {
    const params  = _.map(selectedOrders, o => o.id);
    const shipments = {uuids: params};
    dispatch(updateOrdersPresale(shipments));
    setOrders(_.differenceBy(orders, selectedOrders, 'id'));
    setSelectedOrders([]);
}

  //Prepare the filters to use the component and show them.
  const appliedFiltersShippingArray = () => {
    if (_.isEmpty(appliedFilters)){
      return [];
    } 
    let cleanFilters = _.omitBy(appliedFilters, _.isEmpty);
    let modalFilters = _.cloneDeep(cleanFilters);
    
    if(!_.isEmpty(modalFilters.days)){
      modalFilters.days = modalFilters.daysOption + ' ' + modalFilters.days;
    }
    if(!_.isEmpty(modalFilters.hours)){
      modalFilters.hours = modalFilters.hoursOption + ' ' +  modalFilters.hours;
    }
    modalFilters = _.omit(modalFilters, "daysOption");
    modalFilters = _.omit(modalFilters, "hoursOption");

    return _.map(_.toPairs(modalFilters), (value) => ({type: value[0], value: value[1]}));
  };

  return (
    <div>
      <div key="search_wrapper" className={style_sheet.actionRow}>
        <SearchSelect updateItems={updateFilterItems} list={isPreSale ? 'pre_sale' : 'back_order'}/>
        <ShippingFiltersModal
          filters={appliedFilters}
          are_applied_filters={isAppliedFilters}
          handleApplyFilters={handleApplyFilters}
          visible={isFiltersModalVisible}
          toggleVisibility={() => setIsFiltersModalVisible(!isFiltersModalVisible)} />
      </div>
      <AppliedFilters
          key="applied_filters"
          query={queryDone}
          filters={appliedFiltersShippingArray()}
          is_fetching={fetching}
          total_count={totalCount}
          removeFilter={removeFilter}
          clearFilters={clearFilters}/>
      <ShippingMultiOptions selectedOrders={selectedOrders} confirmPresaleOrder={true} confirmOrder={false} createLabel={false} pickList={true} print={false} isPresale={isPreSale} confirmAllOrders={confirmAllOrders} />
      {orders && <div className={style_sheet.totalOrders}>Total Orders: {totalCount}</div>}
      <ShippingTable orders={orders} selectRow={selectRow} multiSelect={true} selectAllRow={selectAllRow} selectedOrders={selectedOrders} origin={isPreSale ? 'presale' : 'backorder'}/>
      <MBTable.Footer
          is_fetching={fetching}
          is_empty={_.isEmpty(orders)}
          has_more={nextPage <= totalPages}
          loadMore={attemptNextPageFetch}
          empty_message={I18n.t('ui.table.empty.shipping')}/>
    </div>
  )
}
  
  
export default PreSaleWrapper;