//@flow

import { globalizeSelectors } from '../utils/globalizeSelectors';
import type { LocalState } from './reducer';

const LOCAL_PATH = 'notification';

// local selectors
export const displayOrder = (state: LocalState) => state.display_order;
export const totalCount = (state: LocalState) => state.display_order.length;
export const unconfirmedShipments = (state: LocalState) => state;

// global selectors
const selectors = {
  ...globalizeSelectors(LOCAL_PATH, {
    displayOrder,
    totalCount,
    unconfirmedShipments
  })
};

export default selectors;