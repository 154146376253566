// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form_fields_numberInput__x8dcA,\n.form_fields_textInput__vfay4 {\n  border: none;\n  border: solid 1px #9B9B9B;\n  border-radius: 3px;\n  line-height: 30px;\n  font-size: 14px;\n  padding: 0 8px;\n  margin-bottom: 16px;\n}\n\n.form_fields_numberInput__x8dcA {\n  width: 100%;\n}\n\n.form_fields_textArea__2IEyl {\n  resize: vertical;\n}\n\n.form_fields_checkbox__3CthP {\n  margin-bottom: 16px;\n}\n\n.form_fields_permanenceField__3-8TE {\n  margin-bottom: 32px;\n}\n\n.form_fields_breakPeriodField__UtxEQ {\n  margin-bottom: 60px;\n}\n\n.form_fields_noDrivers__2kMRg {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  color: #9B9B9B;\n  margin: 16px 0;\n}\n", "",{"version":3,"sources":["webpack://src/views/shared/form_fields/form_fields.module.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EAEE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;EACd,cAAc;AAChB","sourcesContent":[".numberInput,\n.textInput {\n  border: none;\n  border: solid 1px #9B9B9B;\n  border-radius: 3px;\n  line-height: 30px;\n  font-size: 14px;\n  padding: 0 8px;\n  margin-bottom: 16px;\n}\n\n.numberInput {\n  width: 100%;\n}\n\n.textArea {\n  composes: textInput;\n  resize: vertical;\n}\n\n.checkbox {\n  margin-bottom: 16px;\n}\n\n.permanenceField {\n  margin-bottom: 32px;\n}\n\n.breakPeriodField {\n  margin-bottom: 60px;\n}\n\n.noDrivers {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  color: #9B9B9B;\n  margin: 16px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"numberInput": "form_fields_numberInput__x8dcA",
	"textInput": "form_fields_textInput__vfay4",
	"textArea": "form_fields_textArea__2IEyl form_fields_textInput__vfay4",
	"checkbox": "form_fields_checkbox__3CthP",
	"permanenceField": "form_fields_permanenceField__3-8TE",
	"breakPeriodField": "form_fields_breakPeriodField__UtxEQ",
	"noDrivers": "form_fields_noDrivers__2kMRg"
};
export default ___CSS_LOADER_EXPORT___;
