//@flow

import { globalizeSelectors } from '../utils/globalizeSelectors';
import type { LocalState } from './reducer';

const LOCAL_PATH = 'holidays';

// local selectors
export const listHolidays = (state: LocalState) => state?.listHolidays;
export const createHolidayErrors = (state: LocalState) => state?.createHolidayErrors;
export const isFetching = (state: LocalState) => state?.is_fetching;
export const updatingId = (state: LocalState) => state?.updating_id;
export const isCreating = (state: LocalState) => state?.is_creating;
export const successfullyCreated = (state: LocalState) => state?.successfully_created;

const selectors = {
  ...globalizeSelectors(LOCAL_PATH, {
    listHolidays,
    createHolidayErrors,
    isFetching,
    updatingId,
    isCreating,
    successfullyCreated
  })
};

// global selectors
export default selectors;