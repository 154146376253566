// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PreSaleWrapper_totalOrders__294Vk {\n    font-weight: bold;\n}\n\n.PreSaleWrapper_actionRow__3g26i {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n\n@media only screen and (max-width: 767px) and (min-width: 0) {\n    .PreSaleWrapper_sceneWrapper__1y2oZ {\n        padding: 16px 0;\n    }\n\n    .PreSaleWrapper_tab__VsOSq {\n        padding-right: 8px;\n        padding-left: 8px;\n    }\n\n    .PreSaleWrapper_tabContentWrapper__Z8zAb {\n        padding: 0;\n    }\n}", "",{"version":3,"sources":["webpack://src/views/scenes/PreSaleWrapper/PreSaleWrapper.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;;AAEF;IACI;QACI,eAAe;IACnB;;IAEA;QACI,kBAAkB;QAClB,iBAAiB;IACrB;;IAEA;QACI,UAAU;IACd;AACJ","sourcesContent":[".totalOrders {\n    font-weight: bold;\n}\n\n.actionRow {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n\n@media only screen and (max-width: 767px) and (min-width: 0) {\n    .sceneWrapper {\n        padding: 16px 0;\n    }\n\n    .tab {\n        padding-right: 8px;\n        padding-left: 8px;\n    }\n\n    .tabContentWrapper {\n        padding: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"totalOrders": "PreSaleWrapper_totalOrders__294Vk",
	"actionRow": "PreSaleWrapper_actionRow__3g26i",
	"sceneWrapper": "PreSaleWrapper_sceneWrapper__1y2oZ",
	"tab": "PreSaleWrapper_tab__VsOSq",
	"tabContentWrapper": "PreSaleWrapper_tabContentWrapper__Z8zAb"
};
export default ___CSS_LOADER_EXPORT___;
