// @flow

import _ from 'lodash';
import { combineReducers } from 'redux';
import I18n from '../../localization';

import type { Action } from '../store';

const destroy_package_presets_path = 'supplier/package_size_presets/';

export const listPackages = (state: Array<string> = [], action: Action) => {
  switch (action.type){
    case 'PACKAGE_PRESETS:FETCH__SUCCESS':
      return [...action.payload];
      case 'PACKAGE_PRESETS:CREATE__SUCCESS':
        return [action.payload, ...state];
      case 'PACKAGE_PRESETS:DESTROY__SUCCESS':
        const url = action.payload.url.split(destroy_package_presets_path);
        return _.filter(state, s => s.id !== parseInt(url[1]));
    default:
      return state;
  }
};


export const isFetchingReducer = (state: boolean = false, action: Action) => {
  switch (action.type){
    case 'PACKAGE_PRESETS:FETCH__LOADING':
      return true;
    case 'PACKAGE_PRESETS:FETCH__SUCCESS':
    case 'PACKAGE_PRESETS:FETCH__ERROR':
      return false;
    default:
      return state;
  }
};

export const updatingIdReducer = (state: number = null, action: Action) => {
  switch (action.type){
    case 'PACKAGE_PRESETS:UPDATE__LOADING':
      return action.meta.id;
    case 'PACKAGE_PRESETS:UPDATE__SUCCESS':
    case 'PACKAGE_PRESETS:UPDATE__ERROR':
      return null;
    default:
      return state;
  }
};

export const isCreatingReducer = (state: boolean = false, action: Action) => {
  switch (action.type){
    case 'PACKAGE_PRESETS:CREATE__LOADING':
      return true;
    case 'PACKAGE_PRESETS:CREATE__SUCCESS':
    case 'PACKAGE_PRESETS:CREATE__ERROR':
      return false;
    default:
      return state;
  }
};

export const successfullyCreatedReducer = (state: boolean = false, action: Action) => {
  switch (action.type){
    case 'PACKAGE_PRESETS:CREATE__SUCCESS':
      return true;
    case 'PACKAGE_PRESETS:RESET_NEW_PACKAGE_PRESETS_FORM':
    case 'PACKAGE_PRESETS:CREATE__ERROR':
      return false;
    default:
      return state;
  }
};

export const createPackagePresetsErrorsReducer = (state: Array<string> = [], action: Action) => {
  switch (action.type){
    case 'PACKAGE_PRESETS:CREATE__ERROR':{
      if (action.payload.error.message === 'Internal Server Error'){
        return [I18n.t('form.error.invalid')];
      } else {
        return [action.payload.error.message];
      }
    }
    default:
      return state;
  }
};

export type LocalState = {
  listPackages: Array<Object>,
  is_fetching: boolean,
  updating_id: number,
  is_creating: boolean,
  createPackagePresetsErrors: Array<string>,
  successfully_created: boolean
};

const packagePresetsReducer = combineReducers({
  listPackages: listPackages,
  is_fetching: isFetchingReducer,
  updating_id: updatingIdReducer,
  is_creating: isCreatingReducer,
  createPackagePresetsErrors: createPackagePresetsErrorsReducer,
  successfully_created: successfullyCreatedReducer
});

export default packagePresetsReducer;
