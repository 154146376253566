// @flow

import _ from 'lodash';
import { combineReducers } from 'redux';
import I18n from '../../localization';

import type { Action } from '../store';

const destroy_holiday_path = 'supplier/holidays/';

export const listHolidaysReducer = (state: Array<string> = [], action: Action) => {
  switch (action.type){
    case 'HOLIDAY:FETCH__SUCCESS':
      return [...action.payload];
      case 'HOLIDAY:CREATE__SUCCESS':
        return [action.payload, ...state];
      case 'HOLIDAY:DESTROY__SUCCESS':
        const url = action.payload.url.split(destroy_holiday_path);
        return _.filter(state, s => s.id !== parseInt(url[1]));
    default:
      return state;
  }
};


export const isFetchingReducer = (state: boolean = false, action: Action) => {
  switch (action.type){
    case 'HOLIDAY:FETCH__LOADING':
      return true;
    case 'HOLIDAY:FETCH__SUCCESS':
    case 'HOLIDAY:FETCH__ERROR':
      return false;
    default:
      return state;
  }
};

export const updatingIdReducer = (state: number = null, action: Action) => {
  switch (action.type){
    case 'HOLIDAY:UPDATE__LOADING':
      return action.meta.id;
    case 'HOLIDAY:UPDATE__SUCCESS':
    case 'HOLIDAY:UPDATE__ERROR':
      return null;
    default:
      return state;
  }
};

export const isCreatingReducer = (state: boolean = false, action: Action) => {
  switch (action.type){
    case 'HOLIDAY:CREATE__LOADING':
      return true;
    case 'HOLIDAY:CREATE__SUCCESS':
    case 'HOLIDAY:CREATE__ERROR':
      return false;
    default:
      return state;
  }
};

export const successfullyCreatedReducer = (state: boolean = false, action: Action) => {
  switch (action.type){
    case 'HOLIDAY:CREATE__SUCCESS':
      return true;
    case 'HOLIDAY:RESET_NEW_HOLIDAY_FORM':
    case 'HOLIDAY:CREATE__ERROR':
      return false;
    default:
      return state;
  }
};

export const createHolidayErrorsReducer = (state: Array<string> = [], action: Action) => {
  switch (action.type){
    case 'HOLIDAY:CREATE__ERROR':{
      if (action.payload.error.message === 'Internal Server Error'){
        return [I18n.t('form.error.invalid')];
      } else {
        return [action.payload.error.message];
      }
    }
    default:
      return state;
  }
};

export type LocalState = {
  listHolidays: Array<Object>,
  is_fetching: boolean,
  updating_id: number,
  is_creating: boolean,
  createHolidayErrors: Array<string>,
  successfully_created: boolean
};

const holidayReducer = combineReducers({
  listHolidays: listHolidaysReducer,
  is_fetching: isFetchingReducer,
  updating_id: updatingIdReducer,
  is_creating: isCreatingReducer,
  createHolidayErrors: createHolidayErrorsReducer,
  successfully_created: successfullyCreatedReducer
});

export default holidayReducer;
