import _ from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, List, ListContent, ListItem } from 'semantic-ui-react';
import { assignCustomTag, destroyCustomTag, fetchCustomTags, unassignCustomTag } from '../../../../business/custom_tags/actions';
import { isAssigning, isDestroying, listTags, successfullyDestroyed } from '../../../../business/custom_tags/selectors';
import { fetchShipped } from '../../../../business/order/actions';
import style_sheet from './CreateCustomTag.module.css';

const CustomTagList = ({orderId, customTags, origin}) => {
    const dispatch = useDispatch();
    const tags = useSelector((state) => listTags(state));
    const destroying = useSelector((state) => isDestroying(state));
    const assigning = useSelector((state) => isAssigning(state));
    const destroyed = useSelector((state) => successfullyDestroyed(state));
    
    useEffect(() => {
        if(destroyed){
            dispatch(fetchCustomTags());
            if(origin === 'shipping'){
                dispatch(fetchShipped());
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [destroyed])
    
    const assign = (tag) => {
        dispatch(assignCustomTag({tag: tag.id, order: orderId}));
    }

    const unassign = (tag) => {
        dispatch(unassignCustomTag({tag: tag.id, order: orderId}));
    }

    const deleteCustomTag = (tag) => {
        dispatch(destroyCustomTag(tag));
    }

    return  <List selection  verticalAlign='middle'> 
    {_.map(tags, (tag) => {
        return <ListItem key={tag.name}>
                <ListContent>
                    <Button floated='right' color='red' icon size='medium' loading={destroying} onClick={() => deleteCustomTag(tag.id)}><Icon name='trash'/></Button>
                    {_.find(customTags, (custom) => {return custom.id === tag.id}) ? 
                    <Button floated='right' color='grey' icon size='medium' loading={assigning} onClick={() => unassign(tag)}><Icon name='minus'/></Button> :
                    <Button floated='right' color='green' icon size='medium' loading={assigning} onClick={() => assign(tag)}><Icon name='plus'/></Button>
                    }
                    <span className={style_sheet.tagDot} style={{backgroundColor: tag.color}}></span>
                    <span className={style_sheet.tagText}>{tag.name}</span>
                </ListContent>
        </ListItem>
    })}
    </List>
}

export default CustomTagList;