import { useEffect } from "react";


const RefreshComponent = () => {
    const THREEHOURS = 60000 * 30;
    useEffect(() => {
        const interval = setInterval(() => {
            window.location.reload(true);
        }, THREEHOURS)
        return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },  [])

    return <></>
}

export default RefreshComponent;