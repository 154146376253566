// @flow

import React, {PureComponent} from 'react';
import {Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux';
import * as Ent from '@minibar/store-business/src/utils/ent';
import {order_helpers} from '../../../business/order';
import style_sheet from './form_fields.module.css';

const {deliveryServiceOptions} = order_helpers;

type DeliveryServiceFieldProps = { onChangeText: (string, Object) => void, options: Array<Object>, formFields: { [string]: MBFormField }, 
secondary_delivery_service: Object, primary_delivery_service: Object, show_dsp_flipper: Boolean };

class DeliveryServiceField extends PureComponent {
  props: DeliveryServiceFieldProps

  static defaultProps = {name: 'delivery_service_id'}
  input: ReactClass<*>

  render() {
    const disableDropdown = this.props.formFields?.use_delivery_service?.value === 'true' ||  
    (!this.props.show_dsp_flipper && (this.props.secondary_delivery_service || this.props.primary_delivery_service));

    return (
      disableDropdown &&
      <DeliveryServiceDropdown
        order_id={this.props.order_id}
        onChangeText={this.props.onChangeText}
        className={style_sheet.textInput}/>
    );
  }
}

const DeliveryServiceDropdownSTP = () => {
  const findOrder = Ent.find('order');
  return (state, {order_id}) => {
    const order = findOrder(state, order_id);
    return {
      order,
      options: deliveryServiceOptions(order)
    };
  };
};
const connectDeliveryServiceDropdown = connect(DeliveryServiceDropdownSTP);
const DeliveryServiceDropdown = connectDeliveryServiceDropdown(({
                                                                    options,
                                                                    order,
                                                                    onChangeText,
                                                                    ...dropdown_props
                                                                  }) => {
  const handleChange = (_e, {value}) => {
    onChangeText(value, true);
  };
  return (
    <Dropdown selection options={options} onChange={handleChange} {...dropdown_props} />
  );
});
export default DeliveryServiceField;
