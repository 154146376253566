import { useEffect } from 'react';
import MBTable from '../../../elements/MBTable';
import style_sheet from './CarrierConfiguration.module.css';
import NewCarrierConfigurationModal from './NewCarrierConfigurationButton';
import { carrier_account_helpers, carrier_account_selectors } from '../../../../business/carrier_accounts';
import MBLoader from '../../../elements/MBLoader';
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { destroyCarrierAccount, fetchCarrierAccounts } from '../../../../business/carrier_accounts/actions';
import I18n from '../../../../localization';
import MBIcon from '../../../elements/MBIcon';
import MBButton from '../../../elements/MBButton';
import { isFetching } from '../../../../business/carrier_accounts/selectors';


 
const {CARRIER_ACCOUNT_COLUMN_ORDER} = carrier_account_helpers;
const { listAccounts } = carrier_account_selectors;

const CarrierConfiguration = () => {
    const fetching = useSelector((state) => isFetching(state));
    const dispatch = useDispatch();
    const accountsList = useSelector((state) => listAccounts(state));
    useEffect(() =>{
      dispatch(fetchCarrierAccounts());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    


    const renderTableHeader = () => (
        <MBTable.Header>
          {CARRIER_ACCOUNT_COLUMN_ORDER.map(column_name => {
            if (column_name === 'mobile_summary') return null; // no header for mobile summary column
            return (
              <MBTable.HeaderCell mobile_hidden
                                  key={`${column_name}header`}>{I18n.t(`ui.table.${column_name}`)}</MBTable.HeaderCell>
            );
          })}
        </MBTable.Header>
      );
    
    const renderTableBody = (carriers) => {
        if (fetching) return <MBLoader/>;
        return (<MBTable.Body>{carriers.map(carrier => renderRow(carrier))}</MBTable.Body>);
      };
    
    const renderRow = (carrier) => {
        return (<MBTable.Row key={`${carrier.carrier}row`}>
        {CARRIER_ACCOUNT_COLUMN_ORDER.map(col => {
          return (
            <MBTable.Cell
              key={`${carrier.carrier}${col}`}
              mobile_only={col === 'mobile_summary'}
              mobile_hidden={col !== 'mobile_summary'}>
              {formatCellDatum(col, carrier)}
            </MBTable.Cell>
          );
        })}
      </MBTable.Row>);
    };

    const deleteCarrierAccount = (id) => {
        if (window.confirm(I18n.t('ui.alert.confirm_delete_account'))) {
            dispatch(destroyCarrierAccount(id));
        }
    }

    const renderButton = (carrier) => {
      return <MBButton
                title={I18n.t('accessibility.title.delete_carrier')}
                onClick={() => deleteCarrierAccount(carrier.id)}
                button_type="link"
                size="large">
                <MBIcon icon="delete" color="mb_red" size="small" />
                <div>{I18n.t('ui.button.delete_carrier')}</div>
            </MBButton>
    }
        
    

    const formatCellDatum = (key, carrier) => {
        switch (key) {
            case 'carrier':
                return <p>{carrier.carrier}</p>
            case 'address1':
                return <p>{carrier.address?.address1}</p>
            case 'address2':
                return <p>{carrier.address?.address2}</p>
            case 'state':
                  return <p>{carrier.address?.state}</p>
            case 'phone':
                return <p>{carrier.address?.phone}</p>
            case 'edit':
                return (renderButton(carrier));
            default:
              return <p></p>;
          }
    }

    return (
        <div className={style_sheet.tableWrapper}>
            <NewCarrierConfigurationModal />
            {!_.isEmpty(accountsList) &&
                <MBTable.Table>
                {renderTableHeader()}
                {renderTableBody(accountsList)}
                </MBTable.Table>
            }
            
        </div>

    );
}


export default CarrierConfiguration; 