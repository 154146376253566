// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.ShippingListWrapper_actionRow__2gIWO {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.ShippingListWrapper_totalOrders__-x2qJ {\n    font-weight: bold;\n}\n\n\n@media only screen and (max-width: 767px) and (min-width: 0) {\n    .ShippingListWrapper_sceneWrapper__1-n9z {\n        padding: 16px 0;\n    }\n\n    .ShippingListWrapper_tab__2XD1n {\n        padding-right: 8px;\n        padding-left: 8px;\n    }\n\n    .ShippingListWrapper_tabContentWrapper__3AAmg {\n        padding: 0;\n    }\n}", "",{"version":3,"sources":["webpack://src/views/scenes/ShippingListWrapper/ShippingListWrapper.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;;AAGA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,kBAAkB;QAClB,iBAAiB;IACrB;;IAEA;QACI,UAAU;IACd;AACJ","sourcesContent":["\n.actionRow {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.totalOrders {\n    font-weight: bold;\n}\n\n\n@media only screen and (max-width: 767px) and (min-width: 0) {\n    .sceneWrapper {\n        padding: 16px 0;\n    }\n\n    .tab {\n        padding-right: 8px;\n        padding-left: 8px;\n    }\n\n    .tabContentWrapper {\n        padding: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionRow": "ShippingListWrapper_actionRow__2gIWO",
	"totalOrders": "ShippingListWrapper_totalOrders__-x2qJ",
	"sceneWrapper": "ShippingListWrapper_sceneWrapper__1-n9z",
	"tab": "ShippingListWrapper_tab__2XD1n",
	"tabContentWrapper": "ShippingListWrapper_tabContentWrapper__3AAmg"
};
export default ___CSS_LOADER_EXPORT___;
