import { useEffect, useState } from "react";
import { Button, Icon, Popup, Table, TableBody, TableCell, TableRow } from "semantic-ui-react";
import I18n from '../../../localization';
import _ from 'lodash';
import { displayState, nextState, recipientName } from "../../../business/order/helpers";

import { fetchShipped, updateOrders } from "../../../business/order/actions";
import { useDispatch, useSelector } from "react-redux";
import { isBulkUpdated, isUpdatingBulk } from "../../../business/order/selectors";

const ConfirmOrderPopup = ({orders}) => {
    const cleanOrders = _.filter(orders, (o) => (displayState(o) === 'UNCONFIRMED'))
    const [visible, setVisible] = useState(false);
    const updating = useSelector((state) => isUpdatingBulk(state));
    const bulkUpdated = useSelector((state) => isBulkUpdated(state));
    
    const dispatch = useDispatch();

    useEffect(() => {
        if(updating){
            setVisible(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updating]);

    useEffect(() => {
        if(bulkUpdated){
            dispatch(fetchShipped({sort_column: "created_at", sort_direction: "desc", per_page: 25,page: 1}));
            setVisible(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bulkUpdated]);

    const confirmAllOrders = () => {
        const params  = _.map(cleanOrders, o => ({uuid: o.id, state: nextState(o)}));
        const shipments = {shipments: params};
        dispatch(updateOrders(shipments));
    }

    return (
        <Popup open={visible} flowing hoverable trigger={<Button
            disabled={_.isEmpty(cleanOrders)}
            color="green"
            loading={updating}
            title={I18n.t('accessibility.title.confirm')}
            onClick={() => setVisible(!visible)}>
            <Icon name='check' />
            {I18n.t('ui.button.confirm')}
        </Button>} position="bottom right">
            <div>
                <h3>Orders to Confirm</h3>
                <Table>
                    <TableBody>
                        
                        {_.map(cleanOrders, (o) => (
                            <TableRow>
                                <TableCell>
                                    {recipientName(o)}
                                </TableCell>
                                <TableCell>
                                    {displayState(o)}
                                </TableCell>
                            </TableRow>))}
                    </TableBody>
                </Table>
                <Button
                    fluid
                    color="green"
                    loading={updating}
                    title={I18n.t('accessibility.title.confirm_all')}
                    onClick={() => confirmAllOrders()}>
                    {I18n.t('ui.button.confirm_all')}
                </Button>
            </div>
        </Popup>
    )

}

export default ConfirmOrderPopup;