// @flow
import * as Ent from '@minibar/store-business/src/utils/ent';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import style_sheet from './OrderListWrapper.module.css';
import {session_selectors} from '../../../business/session';
import {notification_selectors} from "../../../business/notification";
import {order_actions} from '../../../business/order';
import NotificationCard from "./NotificationCard";
import _ from 'lodash';

// TODO: on scroll virtualize
const notificationFinder = Ent.find('notification');
export const NotificationFeed = () => {
  const dispatch = useDispatch()
  const notifications = useSelector((state) => notificationFinder(state, notification_selectors.displayOrder(state)))
  const unconfirmedShipments = useSelector((state) => session_selectors.unconfirmedShipments(state) || [])
  const notificationsOrder = _.orderBy(notifications, ["notification_type"], ["asc"]);

  useEffect(() => {
    const [order] = unconfirmedShipments.filter((item) => {
      return !notifications.some(notification => notification?.order_id === item?.id)
    })
    if(order) {
      dispatch(order_actions.fetchOrder(order.id))
    }
  },[dispatch, notifications, unconfirmedShipments])

  return (
    <ReactCSSTransitionGroup
      className={style_sheet.feedWrapper}
      transitionAppear
      transitionAppearTimeout={700}
      transitionName={style_sheet}
      transitionEnterTimeout={700}
      transitionLeaveTimeout={700}>
      {notificationsOrder && notificationsOrder.map((notification) => (
        <NotificationCard
          key={notification.id}
          notification_id={notification.id}
          notification_type={notification.notification_type}
          order_id={notification.order_id}/>
      ))}
    </ReactCSSTransitionGroup>
  );
};

export default NotificationFeed;
