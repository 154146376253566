// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrderAttributeIcons_attributeCell__1bJ47 {\n  display: flex;\n}\n\n.OrderAttributeIcons_attributeIcon__jsP9f {\n  height: 24px;\n  width: 24px;\n  margin-right: 8px;\n}", "",{"version":3,"sources":["webpack://src/views/shared/OrderAttributeIcons/OrderAttributeIcons.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;AACnB","sourcesContent":[".attributeCell {\n  display: flex;\n}\n\n.attributeIcon {\n  height: 24px;\n  width: 24px;\n  margin-right: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"attributeCell": "OrderAttributeIcons_attributeCell__1bJ47",
	"attributeIcon": "OrderAttributeIcons_attributeIcon__jsP9f"
};
export default ___CSS_LOADER_EXPORT___;
