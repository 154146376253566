// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home_container__1Z5C6 {\n  height: 100%;\n}\n\n.Home_loaderRow__oOBZj {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 300px;\n}", "",{"version":3,"sources":["webpack://src/views/shared/Home/Home.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf","sourcesContent":[".container {\n  height: 100%;\n}\n\n.loaderRow {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 300px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Home_container__1Z5C6",
	"loaderRow": "Home_loaderRow__oOBZj"
};
export default ___CSS_LOADER_EXPORT___;
