//@flow

import type { CarrierAccount } from './index';

export const CARRIER_ACCOUNT_COLUMN_ORDER = ['mobile_summary', 'carrier','address1','address2', 'edit'];

export const carrierAccountTableRow = (carrier_account: CarrierAccount) => {
  return ({
    carrier: carrier_account.carrier
  });
};
