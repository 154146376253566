import { useEffect } from 'react';
import MBTable from '../../../elements/MBTable';
import style_sheet from './PackagePresets.module.css';

import { package_presets_helpers, package_presets_selectors } from '../../../../business/package_presets';
import MBLoader from '../../../elements/MBLoader';
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { destroyPackagePresets, fetchPackagePresets } from '../../../../business/package_presets/actions';
import I18n from '../../../../localization';
import MBIcon from '../../../elements/MBIcon';
import MBButton from '../../../elements/MBButton';
import { isFetching } from '../../../../business/package_presets/selectors';
import NewPackagePresetButton from './NewPackagePresetButton';


 
const {PACKAGE_PRESETS_COLUMN_ORDER} = package_presets_helpers;
const { listPackages } = package_presets_selectors;

const PackagePresets = () => {
    const fetching = useSelector((state) => isFetching(state));
    const dispatch = useDispatch();
    const packageList = useSelector((state) => listPackages(state));

    useEffect(() =>{
      dispatch(fetchPackagePresets());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderTableHeader = () => (
      <MBTable.Header>
        {PACKAGE_PRESETS_COLUMN_ORDER.map(column_name => {
          if (column_name === 'mobile_summary') return null; // no header for mobile summary column
          return (
            <MBTable.HeaderCell mobile_hidden
                                key={`${column_name}header`}>{I18n.t(`ui.table.${column_name}`)}</MBTable.HeaderCell>
          );
        })}
      </MBTable.Header>
    );

    const renderTableBody = (packagepresets) => {
        if (fetching) return <MBLoader/>;
        return (<MBTable.Body>{packagepresets.map(packagepresets => renderRow(packagepresets))}</MBTable.Body>);
      };
    
    const renderRow = (packagepresets) => {
      return (<MBTable.Row key={`${packagepresets.packagepresets}row`}>
        {PACKAGE_PRESETS_COLUMN_ORDER.map(col => {
          return (
            <MBTable.Cell
              key={`${packagepresets.packagepresets}${col}`}
              mobile_only={col === 'mobile_summary'}
              mobile_hidden={col !== 'mobile_summary'}>
              {formatCellDatum(col, packagepresets)}
            </MBTable.Cell>
          );
        })}
      </MBTable.Row>);
    };

    const deletePackagePresets = (id) => {
        if (window.confirm(I18n.t('ui.alert.confirm_delete_preset'))) {
            dispatch(destroyPackagePresets(id));
        }
    }

    const renderButton = (packagepresets) => {
      return <MBButton
                title={I18n.t('accessibility.title.delete_packagepresets')}
                onClick={() => deletePackagePresets(packagepresets.id)}
                button_type="link"
                size="large">
                <MBIcon icon="delete" color="mb_red" size="small" />
                <div>{I18n.t('ui.button.delete_packagepresets')}</div>
            </MBButton>
    }
        
    

    const formatCellDatum = (key, packagepresets) => {
        switch (key) {
            case 'bottle_count':
                return <p>{packagepresets.bottle_count}</p>
            case 'width':
                return <p>{packagepresets.dimensions?.width}</p>
            case 'height':
                return <p>{packagepresets.dimensions?.height}</p>
            case 'length':
                  return <p>{packagepresets.dimensions?.length}</p>
            case 'weight':
                return <p>{packagepresets.weight?.value}</p>
            case 'edit':
                return (renderButton(packagepresets));
            default:
              return <p></p>;
          }
    }

    return (
        <div className={style_sheet.tableWrapper}>

            <NewPackagePresetButton />
            {!_.isEmpty(packageList) &&
                <MBTable.Table>
                {renderTableHeader()}
                {renderTableBody(packageList)}
                </MBTable.Table>
            }
            
        </div>

    );
}


export default PackagePresets; 