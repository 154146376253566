export function getSessionKeywords(keyword){
    const stored = sessionStorage.getItem(keyword)
    if(!stored){
        return [];
    }
    return JSON.parse(stored);
}

export function saveSessionKeywords(keyword, value){
    sessionStorage.removeItem(keyword)
    sessionStorage.setItem(keyword, value)
}

