import { useState } from "react";
import { Button, Icon, Popup, Table, TableBody, TableCell, TableRow } from "semantic-ui-react";
import I18n from '../../../localization';
import _ from 'lodash';
import { displayState, recipientName } from "../../../business/order/helpers";
import {  useSelector } from "react-redux";
import { isUpdatingBulk } from "../../../business/order/selectors";

const ConfirmPresaleOrderPopup = ({orders, isPresale, confirmAllOrders}) => {
    const [visible, setVisible] = useState(false);
    const updating = useSelector((state) => isUpdatingBulk(state));

    const bulkReady = () => {
        setVisible(false);
        confirmAllOrders();
    }

    return (
        <Popup open={visible} flowing hoverable trigger={<Button
            disabled={_.isEmpty(orders)}
            color="green"
            loading={updating}
            title={I18n.t('accessibility.title.ready_presale_selected')}
            onClick={() => setVisible(!visible)}>
            <Icon name='check' />
            {I18n.t('ui.button.ready')}
        </Button>} position="bottom right">
            <div>
                <h3>Orders to Confirm</h3>
                <Table>
                    <TableBody>
                        
                        {_.map(orders, (o) => (
                            <TableRow>
                                <TableCell>
                                    {recipientName(o)}
                                </TableCell>
                                <TableCell>
                                    {displayState(o)}
                                </TableCell>
                            </TableRow>))}
                    </TableBody>
                </Table>
                <Button
                    fluid
                    color="green"
                    loading={updating}
                    title={I18n.t('accessibility.title.confirm_all')}
                    onClick={() => bulkReady()}>
                    {I18n.t('ui.button.confirm_all')}
                </Button>
            </div>
        </Popup>
    )

}

export default ConfirmPresaleOrderPopup;