// @flow
import {filter, switchMap} from 'rxjs/operators';
import {api} from '../networking';
import createActionsForRequest from '../utils/create_actions_for_request';

export const createHoliday = (action$: Object) => {
  const holiday_response_action$ = action$
    .pipe(
      filter(action => action.type === 'HOLIDAY:CREATE'),
      switchMap(action => {
        return createActionsForRequest(api.createHoliday({...action.payload}), action.type, action.meta);
      }));

  return holiday_response_action$;
};

export const destroyHoliday = (action$: Object) => {
  const holiday_response_action$ = action$
    .pipe(
      filter(action => action.type === 'HOLIDAY:DESTROY'),
      switchMap(action => {
        return createActionsForRequest(api.destroyHoliday(action.payload), action.type, action.meta);
      }));

  return holiday_response_action$;
};

export const fetchHolidays = (action$: Object) => {
  const holiday_response_action$ = action$
    .pipe(
      filter(action => action.type === 'HOLIDAY:FETCH'),
      switchMap(action => {
        return createActionsForRequest(api.fetchHolidays(), action.type, action.meta);
      }));

  return holiday_response_action$;
};

