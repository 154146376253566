// @flow
import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames/bind';
import _ from 'lodash';
import style_sheet from './PackagePresets.module.css';
import NewFormError from './../NewFormError';
import I18n from '../../../../localization';
import {package_presets_actions, package_presets_selectors} from '../../../../business/package_presets';
import {MBForm, MBFormSubmitButton} from '../../../elements/MBForm';
import {
  NameField 
} from '../../../shared/form_fields';

const cx = classNames.bind(style_sheet);

type PackagePresetsFormProps = {
  createPreset: (Object) => void,
  close: () => void,
  resetForm: () => void,
  create_package_presets_errors: Array<string>,
  is_loading: boolean,
  successfully_created: boolean
};
export const PackagePresetsForm = ({
                                  createPreset,
                                  close,
                                  resetForm,
                                  create_package_presets_errors,
                                  is_loading,
                                  successfully_created
                                }: PackagePresetsFormProps) => {
  if (successfully_created) {
    close();
  }
  return (
    <MBForm
      onSubmit={(values) => {
        resetForm();
        const {count, length, width, height, weight} = values
        const preset = {
          package_size_preset:{
            bottle_count: count, 
            dimensions: {
              length: length,
              width: width,
              height: height
            },
            weight: {
              value: weight,
              unit: "pound"
            }
          }
        }
        createPreset(preset);
      }}
      className={cx('newForm', {shake: !_.isEmpty(create_package_presets_errors)})}>
        <FieldLabel key="count">{I18n.t('ui.modal.packagePreset.label.bottle_count')}</FieldLabel>
        <NameField name="count" type="number" />
        <FieldLabel key="count">{I18n.t('ui.modal.packagePreset.label.length')}</FieldLabel>
        <NameField name="length" type="number"/>
        <FieldLabel key="count">{I18n.t('ui.modal.packagePreset.label.width')}</FieldLabel>
        <NameField name="width" type="number"/>
        <FieldLabel key="count">{I18n.t('ui.modal.packagePreset.label.height')}</FieldLabel>
        <NameField name="height" type="number"/>
        <FieldLabel key="count">{I18n.t('ui.modal.packagePreset.label.weight')}</FieldLabel>
        <NameField name="weight" type="number"/>
      <MBFormSubmitButton
        is_loading={is_loading}
        text={I18n.t('ui.modal.package_preset.submit')}/>
      <NewFormError error_messages={create_package_presets_errors}/>
    </MBForm>
  );
};

const FieldLabel = ({children}) => <p className={style_sheet.fieldLabel}>{children}</p>;

const PackagePresetsFormSTP = state => ({
  create_package_presets_errors: package_presets_selectors.createPackagePresetsErrors(state),
  is_loading: package_presets_selectors.isCreating(state),
  successfully_created: package_presets_selectors.successfullyCreated(state)
});
const PackagePresetsFormDTP = {
  createPreset: package_presets_actions.createPackagePresets,
  resetForm: package_presets_actions.resetNewPackagePresetForm
};
const PackagePresetsFormContainer = connect(PackagePresetsFormSTP, PackagePresetsFormDTP)(PackagePresetsForm);

export default PackagePresetsFormContainer;
