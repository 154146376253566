// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MBIcon_iconWrapper__1OQua {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.MBIcon_inline__2wVak {\n  display: inline-flex !important;\n}\n\n.MBIcon_clickable__2HvGU:hover {\n  cursor: pointer;\n}\n\n@media only screen and (max-width: 767px) and (min-width: 0) {\n  .MBIcon_mobileHidden__ywUHj {\n    display: none !important;\n  }\n}", "",{"version":3,"sources":["webpack://src/views/elements/MBIcon/MBIcon.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,wBAAwB;EAC1B;AACF","sourcesContent":[".iconWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.inline {\n  display: inline-flex !important;\n}\n\n.clickable:hover {\n  cursor: pointer;\n}\n\n@media only screen and (max-width: 767px) and (min-width: 0) {\n  .mobileHidden {\n    display: none !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconWrapper": "MBIcon_iconWrapper__1OQua",
	"inline": "MBIcon_inline__2wVak",
	"clickable": "MBIcon_clickable__2HvGU",
	"mobileHidden": "MBIcon_mobileHidden__ywUHj"
};
export default ___CSS_LOADER_EXPORT___;
