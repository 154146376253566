import MBTable from "../../elements/MBTable";
import I18n from '../../../localization';
import _ from 'lodash';
import classNames from "classnames/bind";
import style_sheet from './ShippingTable.module.css';
import MBIcon from "../../elements/MBIcon";
import OrderAttributeIcons from "../OrderAttributeIcons";
import { isException, shippingTableRow } from "../../../business/order/helpers";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Icon, List, ListItem, Popup } from "semantic-ui-react";
import { updateOrderPresale } from "../../../business/order/actions";
import { useDispatch, useSelector } from "react-redux";
import CreateCustomTag from "../OrderTable/CustomTags/CreateCustomTag";
import CustomTagTable from "../OrderTable/CustomTags/CustomTagsTable";
import ShippingOrderStateModal from "../ShippingOrderStateModal/ShippingOrderStateModal";
import MBLoader from "../../elements/MBLoader";
import { orderAll } from "../../../business/order/selectors";

const cx = classNames.bind(style_sheet);

const ShippingTable = ({orders, selectRow, selectAllRow, selectedOrders, multiSelect, origin}) => {
  const [sortedOrders, setSortedOrders] = useState([]);
  const dispatch = useDispatch();  
  const [isAllChecked, setIsAllChecked] = useState(false);

  const [shippingColumns, setShippingColumns] = useState([
    {col: 'mobile_summary'}, 
    {col: 'checkbox'}, 
    {col: 'recipient'},
    {col: 'status'},
    {col: 'orderAge', sortCol: 'order_time', sortUp: false},
    {col: 'tags'},
    {col: 'item_sku'},
    {col: 'item_name'},
    {col: 'attributes'},
    {col: 'quantity', sortCol: 'quantity', sortUp: false},
    {col: 'amount'},
    {col: 'update_state'},]);

  const history = useHistory();

  if(!multiSelect){
    _.remove(shippingColumns, option => option.col === 'checkbox');
  }
 
  const pushRoute = (url, params) => {
    history.push(url, params);
  }

  const handleSelectRow = (e, id) => {
    if(multiSelect) selectRow(id); 
  }

  const confirmPresale = (id) => {
    setSortedOrders(_.filter(sortedOrders, o => o.id !== id));
    dispatch(updateOrderPresale(id));
  }

  const sortTable = (col) => {
    setShippingColumns(shippingColumns.map(data => data.col === col && !_.isUndefined(data.sortUp) ? {...data, sortUp: !data.sortUp} : data));
    const dataCol = _.find(shippingColumns, {col:col});
    const sortOrder = !dataCol.sortUp ? 'asc' : 'desc';
    setSortedOrders(_.orderBy(sortedOrders, [dataCol.sortCol], [sortOrder]));
  }

  useEffect(() => {
    const orderList = _.map(orders, order => { return shippingTableRow(order) });
    setSortedOrders(_.orderBy(orderList, ['created_at'], ['desc']));
  }, [orders])

  const isChecked = (id) => {
    return _.find(selectedOrders, o => o.id === id);
  }

  const checkAll = (e) => {
    setIsAllChecked(!isAllChecked);
    selectAllRow(!isAllChecked);
  }

  const listItemsPopup = (itemList) => {
    if(itemList.length > 1){
      return <div>
              <p>(Multiple)
                <Popup position="bottom center" trigger={<Icon name='list' color='blue' size="small"/>}>
                   <List bulleted>
                      {_.map(itemList, (item, key) => {
                        return <ListItem key={key}>{item}</ListItem>
                      })}
                   </List>
                </Popup>
              </p>
            </div>
    }
    return <p>{itemList}</p>
  }

  const formatCellDatum = (col, row, showSubstitutionOkIcon) => {
    switch (col) {
      case 'status': {
        return <p className={cx('orderStatus', row.status.class)}>{row[col].text}</p>;
      }
      case 'item_sku': {
        return listItemsPopup(row[col])
      }
      case 'item_name': {
        return listItemsPopup(row[col])
      }
      case 'orderAge': {
        return <p className={style_sheet[row[col].class]}>{row[col].value}</p>;
        
      }
      case 'checkbox': {
        return <input type="checkbox" checked={isChecked(row.id)} id={`${row.id}checkbox`} name={row.id} onChange={(e) => handleSelectRow(e, row.id)} />
      }
      case 'update_state': {
        return (
          <div>
              <Button
                color='black'
                title={I18n.t('accessibility.title.view_order')}
                onClick={() => pushRoute(`/order/${row[col].id}`, {origin: `/orders/${origin}`})}>
                <p className={style_sheet.buttonPrint}>{I18n.t('ui.button.view_order')}</p>
              </Button>
              {row[col].updateable && <ShippingOrderStateModal order={row}/>}
            
            {(origin === 'presale' || origin === 'backorder') && 
              <Button
                color='green'
                title={I18n.t('accessibility.title.confirm_presale')}
                onClick={() => confirmPresale(row[col].id)}>
                <span className={style_sheet.buttonPrint}>{I18n.t('ui.button.ready')}</span>
              </Button> }
          </div>
        );
      }
      case 'attributes':
        return <OrderAttributeIcons attributes={row.attributes} label={row.packages?.length > 0} showSubstitutionOk={showSubstitutionOkIcon} storefront={row.storefront}/>;
      case 'tags':
        return <div><CustomTagTable tags={row.custom_tags} /><CreateCustomTag orderId={row.id} customTags={row.custom_tags} origin={origin}/></div>;
      case 'mobile_summary': {
        return (
          <div className={style_sheet.mobileSummaryWrapper}>
            <div className={style_sheet.mobileSummaryLeft}>
              <p className={style_sheet.mobileSummaryMain}>{`${row.recipient} - ${row.amount}`}</p>
              <div className={style_sheet.mobileSummarySecondary}>
                <MBIcon icon={row.method} color="mb_medium_grey" inline size="small"
                        className={style_sheet.deliveryMethodIconMobile}/>
                {row.time}
              </div>
            </div>
            <div className={style_sheet.mobileSummaryRight}>
              <p className={cx('mobileSummaryStatus', 'orderStatus', row.status.class)}>{row.status.text}</p>
              <OrderAttributeIcons attributes={row.attributes} showSubstitutionOk={showSubstitutionOkIcon}/>
            </div>
          </div>
        );
      }
      default:
        return <p className={cx('cellDatum', `${_.camelCase(col)}Cell`)}>{row[col]}</p>;
    }
};

const orderBase = useSelector((state) => orderAll(state));

return orderBase.order.is_fetching ? <MBLoader type="spinner" size="large" className="margin-left: 45%"/> :
    <MBTable.Table>
        <MBTable.Header key="header_row">
        {shippingColumns.map(data => {
          if (data.col === 'mobile_summary') return null; // no header for mobile summary column
          return (
            <MBTable.HeaderCell mobile_hidden key={`${data.col}header`} sort={!_.isUndefined(data.sortUp) ? data.sortUp ? 'sortUp' : 'sortDown' : ''} onClick={(e) => {
              if(!_.isUndefined(data.sortUp)){
                e.preventDefault();
                e.stopPropagation();
                sortTable(data.col);
              }
            }}>
                
            {data.col ==='checkbox' ? <input type="checkbox" checked={isAllChecked} id='checkall' name='checkall' onChange={(e) => checkAll(e)} /> : I18n.t(`ui.table.${data.col}`)}
            </MBTable.HeaderCell>
        );
        })}
        </MBTable.Header>
        <MBTable.Body>
          {_.some(sortedOrders) && sortedOrders.map(order => {
            const exception = isException(order);
            const hasPendingSubstitutions = 0;
            return (
            <MBTable.Row selectable key={`${order.id}row`}>
              {shippingColumns.map(data => (
                <MBTable.Cell
                    mobile_only={data.col === 'mobile_summary'}
                    mobile_hidden={data.col !== 'mobile_summary'}
                    key={`${order.id}${data.col}`}>
                    {formatCellDatum(data.col, order, hasPendingSubstitutions || exception)}
                </MBTable.Cell>
              ))}
            </MBTable.Row>)
          })}
        </MBTable.Body>
    </MBTable.Table>
      
  
}

export default ShippingTable