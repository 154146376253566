// @flow
type AccountDetails = {
  account_number: number,
  first_name: number,
  last_name: number
}

export type CarrierAccount = {
  id: number,
  carrier: string,
  account_details: AccountDetails,
  active: boolean
};



export * as carrier_account_epics from './epics';
export * as carrier_account_actions from './actions';
export * as carrier_account_helpers from './helpers';
export { default as carrierAccountReducer } from './reducer';
export { default as carrier_account_selectors } from './selectors';
